.sign-up-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.sign-up {
    width: 100%;

    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #f9f9f9;
    font-size: var(--font-lg);

    height: 100%;
    margin-inline: 1em;
    font-weight: 500;
    font-size: 22px;
    font-family: "Poppins";
    font-style: normal;
    line-height: 38px;

    transition: all 0.05s ease;
}

.sign-up__left {
    padding-block-end: 1em;
    text-align: center;
}

.sign-up__title {
   font-size: 48px;
   font-weight: 500;
   margin-block-end: 24px;
}

.sign-up__desc {
    font-size: var(--font-size-lg);
    font-weight: 500;
    line-height: var(--line-height-normal);
    color: var(--text-secondary-clr);
}

.sign-up__divider {
    background: linear-gradient(180deg, #08091c 0%, #eb21a0 50%, #08091c 100%);
    width: 5px; 
    height: 60%;
    margin-inline: 1em;

    display: none;
}

.sign-up__left {
    display: block;
    max-width: 430px;
    margin-inline: auto;
}

/* animation */

.sign-up__form {
    padding: 1em;
    background: #100c2a;
    border-radius: 10px;
    width: 100%;
    max-width: 450px;
    margin-inline: auto;
    /* transition: all 1s ease; */
}

.sign-up__name-input {
    margin-block-end: 0;
    transition: margin-block-end 0.3s ease;
}

.sign-up__name-input.hide {
    margin-block-end: -150px;
}

.sign-up__name-input.fade {
    animation: fade 0.05s ease-in forwards;
    /* z-index: -1; */
}

.sign-up__name-input.fast {
    position: relative;
    z-index: -1;
}

.sign-up__name-input.slow {
    position: static;
}

@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        position: relative;
        z-index: -1;
        opacity: 0;
    }
  }

/* animation */

.sign-up__label-name,
.sign-up__label-mobile-number{
    font-weight: 700;
    font-size: var(--font-3lg);
    line-height: 34px;
    letter-spacing: 1px;
}

.sign-up__input {
    width: 100%;
    margin-block: 1em;
    padding-inline: 0.5em;
    padding-block: 0.5em;
    color: #b9b7bf;
    font-size: var(--font-size-lg);
    background: #19122c;
    border-radius: var(--border-6);
    outline: transparent;
}

.sign-up__input:focus {
    outline: transparent;
}

.sign-up__actions-container {
    cursor: pointer;
}

.sign-up__get-otp-btn {
    display: flex;
    align-items: center;
    gap: 10px;

    padding: .1em .5em ;

    font-size: var(--font-size-md);
    color: var(--accent-primary-clr);
    border: 2px solid var(--accent-primary-clr);
    border-radius: 10px;
}

.sign-up__get-otp-btn:active {
    scale: .95;
}

.recaptacha_container_boxify {
    position: fixed;
    bottom: 0;
    right: 1em;
    max-height: 100px;
    max-width: 200px;
}

.sign-up__sub-title {
    display: flex;
    gap: .5em;
    font-size: var(--font-size-md);
    font-weight: 500;
    color: var(--text-secondary-clr);
    line-height: 24px;
}

.sign-up__sub-title.hidden {
    display: none;
 }

.sign-up__input-status {
    color: var(--accent-secondary-clr);
    font-size: var(--font-size-md);
}

.sign-up__login-btn,
.sign-up__signin-btn {
  font-weight: 600;
  color: var(--accent-primary-clr);
  flex-shrink: 0;
}


@media (min-width: 900px) {
    .sign-up {
        width: 100%;
        max-width: var(--app-width);
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
   
    .sign-up__left {
        text-align: left;
        margin-inline: revert;
        width: 100%;
        max-width: 630px;
    }

    .sign-up__divider {
        display: block;
    }

    .sign_up__right {
        display: flex;
        align-items: center;

        width: 100%;
        max-width: 630px;
    } 

    .sign-up__form {
        display: flex;
        flex-direction: column;
       justify-content: center;
        max-width: 100%;
    }

    .sign-up__form__header {
       display: flex;
       justify-content: space-between;
       align-items: center;
    }

    .sign-up__sub-title {
        margin-block-start: 0;
    }

    .sign-up__name-input.hide {
        margin-block-end: -130px;
    }

}

/* otp-form */

.otp-form {
    margin-block-start: 1em;
}

/* .otp-form__title {

} */

.otp-form__inputs-container div {
    /* display: flex; */
    justify-content: space-between;
    gap: 2px;
    background: linear-gradient(180deg, #08091c 0%, #eb21a0 50%, #08091c 100%);
}


.otp-form__btn-container {
    display: flex;
    justify-content: center;
    margin-block: 1.2em;
}
    
.otp-form__get-started-btn {
    padding: .5em 2em;
    border-radius: var(--border-6);
    background-image: linear-gradient(to right, #7B28A9, var(--accent-primary-clr));
    font-size: var(--font-size-lg);
}



