.explanation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    padding: 2em;
    padding-inline-end: 5px;
    padding-block-end: 5px;

    width: 90%;
    max-width: 550px;
	min-width: 240px;
    max-height: 60vh;

    overflow-y: scroll;
    background: var(--fg-tertiary-clr);

	overflow-wrap: break-word;
	
    border: 3px solid var(--stroke-primary-clr);
    border-radius: var(--border-10);

    z-index: 20;

}

.stepexplanationheading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;

    margin-block-end: 1em;

    font-size: 32px;
    line-height: 34px;
    letter-spacing: 1px;
    color: var(--text-primary-clr);
}

.explanationdetails {
    padding: 5px;
    padding-block-end: 1em;
    padding-inline-end: 1em;

    font-family: "Poppins";
    overflow: auto;
    font-style: normal;

    font-weight: 600;
    font-size: 22px;
    line-height: 45px;
    color: var(--text-primary-clr);
    text-align: start;

}

.mathjaxObjectImageDiagrams {
    height: 240px;
}

.close-explanation-modal-btn {
    position: absolute;
    top: 2em;
    right: 2em;
    height: 36px;
    width: 36px;
    border-radius: 50%;
}


@media (min-width: 700px) {
    .explanation-modal {
        max-width: 700px;
    }
}