a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-wrap {
  position: absolute;
}

/* .floating-btn {
  display: block;
  max-width: 200px;
  background: #0397f3;
  position: relative;
  z-index: 1;
  padding: 20px 40px;
  color: white;
  margin: auto;
  box-shadow: 0 2px 0 #615d6c;
  text-decoration: none;
  border-radius: 5px;
  animation: bounce 2s ease-in-out forwards;
  animation-iteration-count: infinite;
} */

.shadow {
  display: block;
  width: 20%;
  height: 0px;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto;
  background: #407392;
  border-radius: 50%;
  animation: bounce-shadow 2s ease-in-out forwards;
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
}
@keyframes bounce-shadow {
  0% {
    width: 1%;
    height: 2px;
    opacity: 0.1;
  }
  50% {
    width: 90%;
    height: 6px;
    opacity: 0.3;
  }
  100% {
    width: 1%;
    height: 0px;
    opacity: 0;
  }
}

/* .navbar {
  display: flex;
  width: 100%;
  align-items: center;
}

.navbar ul {
  display: flex;
  justify-content: space-between;
}

.navbar ul li {
  margin: 0 20px;
  transform-origin: center;
  transition: all 300ms;
} */


.app {
  background-color: var(--bg-primary-clr);

  /* display: flex;
  flex-direction: column;
  min-height: 100vh; */
}