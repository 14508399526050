.chapter-dropdown {

}

.dashboard__dropdown-menu {
    padding-block: .5em;
    /* padding-inline: 2em; */
}

.dashboard__dropdown-menu__chapter-title {
    font-size: var(--font-size-3lg);
    color: var(--accent-primary-clr);

    font-size: clamp(var(--font-size-3lg),  6vw ,var(--font-size-4lg));
}

.chapter-title-container {
    display: flex;
    gap: .25em;
}

.dashboard__chapterQuestionAndTime {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-lg);
    line-height: 34px;
    letter-spacing: 0.75px;

    color: var(--text-secondary-clr);

    display: flex;
    justify-content: flex-start;
    gap: 1.5em;

}

.chapterQuestionAndTimeValue {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;

    font-size: clamp(var(--font-size-sm),  2.5vw ,var(--font-size-lg));
}

.chapterQuestionAndTimeValue > img {
    width: 20px;
}

.chapterListForNewDashBoard {
    position: absolute;
    /* left: 5%; */
    top: 17.5%;
    /* width: 90vw; */
}

.chapter-dropdown__chapterlist {
    position: relative;
}

.chapter-dropdown__modal__chapterList {
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: left;

    width: 100%;
    max-width: 500px; 
    height: 400px;

    background-color: var(--fg-tertiary-clr);
    padding: 0.5rem;
    border: 0.2rem solid #2f99d7;
    border-radius: 0.5rem;

    overflow-y: auto;
    overflow-x: hidden;
    animation: fadeIn ease 1s;

    z-index: 20;
}

@media (min-width: 500px) {
    .chapter-dropdown__modal__chapterList { 
     left: 20%;
    }
}

