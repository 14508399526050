.buyPremiumModalContainer {
	font-family: "Poppins";
	color: var(--text-primary-clr);
	position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
	z-index: 20;
	
	padding: 1em 4vw 2em;
	max-width: 100%;
	
    background: #100c2a;
	box-shadow: 0 0 15px 10px var(--bg-primary-clr);
    border-radius: var(--border-10);
	
	/* @media (width < 600px){
		position: fixed;
		top: 0;
		left: 0;
		translate: 0 0;

		margin: 1em;
		padding: 1em;
		border: 0.2em solid #2f99d7;
		min-width: 100px;
		max-width: 100%;

		text-align: center;
	} */
}

.buyPremiumModalContainerMobile {
	position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
	z-index: 20;
	
	min-width: 300px;
	max-width: 100%;
	padding: 1em;
	border: 0.2em solid #2f99d7;
	
	text-align: center;
    background: #100c2a;
	box-shadow: 0 0 15px 10px var(--bg-primary-clr);
    border-radius: var(--border-10);
	
}

.PurchaseAndAllChapterDiv.PurchaseOnMobile{
	background-color: var(--fg-primary-clr);
	justify-content: space-around;
	padding: 0;
}

.titleAndIntroContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	gap: 0.5em;
    padding: 1em;
}

.MainTitleForThePremiumModal {
    font-weight: 700;
    font-size: var(--font-size-4lg);
    letter-spacing: 1px;
	margin-top: 0.5em;
	
	@media (width<600px) {
		font-size: var(--font-size-3lg);
		letter-spacing: 0;
		padding-block: 0.5em;
	}
}

.subTitleForThePremiumModal {
	font-weight: 500;
    font-size: var(--font-size-md);
    color: #b9b7bf;
	letter-spacing: 0.75px;
	
	@media (width<600px) {
		letter-spacing: 0;
	}
}

.subTitleForThePremiumModal.use-credits {
	padding: 1em;
	font-size: var(--font-size-sm);
	text-align: center;
}

.chapterPremiumPricingModal {
	display: flex;
    justify-content: center;
	gap: 3em;
	
	@media (width < 600px){
		flex-direction: column;
		align-items: center;
	}
}

@media (max-width: 600px) {
	.buyPremiumModalContainer.credit {
		width: 98%;
	}
}
	
.buyPremiumModalContainer.credit {
	padding: 0;
}

.selectedChapterPremiumInformationDiv {
	display: flex;
    flex-direction: column;
    align-items: center;
	
	margin-block: 0.4em;
	padding-bottom: 1em;
	min-width: 200px;
	max-width: 340px;
	flex: 1 1 100%;
	
    background: var(--fg-primary-clr);
    border: 0.2em solid #2f99d7;
    border-radius: 10px;
}

@media (max-width: 600px) {
	.selectedChapterPremiumInformationDiv {
		border: none;
	}
	
	.selectedChapterPremiumInformationDiv.credit {
		max-width: 100%;
		min-width: 0;
		padding-top: 2.5rem;
		border: 0.2em solid #2f99d7;
	}
}

.selectedChapterPremiumInformationDiv.credit {
	position: relative;
	min-width: fit-content;
	padding-bottom: 0;
}

.singleChapterTitleForPremium {
	text-align: center;
	font-weight: 600;
    font-size: var(--font-size-2lg);
    letter-spacing: 1px;
	padding: 1em 4em;
	
	min-width: max-content;
	@media (width < 600px)
	{
		min-width: 0;
	}
}

.singleChapterTitleForPremium.credit {
    font-size: var(--font-size-3lg);
	padding: 1rem 8rem;
}

@media (max-width: 600px ){
	.singleChapterTitleForPremium.credit {
		font-size: var(--font-size-2lg);
		padding: 1rem;
	}
}

.gradiantLineForPremiumModal{
	position: relative;
	padding: 0 1em;
}

.bestBuyLabelForPremium {
    position: absolute;
    top: 50%;
	translate: -1.2em -40%;
	
	max-width: 6.4em;
}

.chapterIconForPremiumModal {
	width: 100px;
    height: 100px;
	margin: 1.5em 0 1em 0;
}

.chapterIconForPremiumModal.credit{
	height: auto;
	margin: 0.5rem auto 0;
	text-align: center;
	font-weight: 700;
    font-size: 60px;
    color: var(--accent-primary-clr);
}

.chapterTitleForPremiumModal {
    font-weight: 600;
    font-size: var(--font-size-lg);

    display: flex;
    align-items: center;
    letter-spacing: 0.75px;

    color: #f9f9f9;
}

.PricingInformationForPremiumModal {
	display: flex;
    align-items: center;
    margin: 1em 2em;
	gap: 0.5em;
}

.PricingInformationForPremiumModal.credit {
	padding: 0 2rem;
	margin: 0.5rem auto;
	text-align: center;
}

.discountInforForPremium {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	
	
	font-size: var(--font-size-md);
	line-height: var(--font-size-md);
    letter-spacing: 1px;
	
    background: linear-gradient(90deg, #f8219f 0%, #7b28a9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.originalPriceForPremium {
	font-weight: 500;
    font-size: var(--font-size-2lg);
    line-height: var(--font-size-2lg);
	
    letter-spacing: 1px;
    text-decoration-line: line-through;
	
    color: #7b28a9;
}

.DiscountedPriceForPremium {
	font-weight: 700;
	font-size: var(--font-size-10lg);
	line-height: var(--font-size-10lg);
	
    color: #f8219f;
}

.creditScore__buttons{
	min-width: fit-content;
	display: flex;
	justify-content:center ;
	align-items: center;
	gap: 1rem;
	padding: 1rem 5rem;
}

@media (max-width: 600px){
	.creditScore__buttons{
		flex-direction: column-reverse;
	}
}

.closebuttonForPremiumModal {
	position: absolute;
    top: 1em;
    right: 1em;
}

.app-btn-1.creditDisabled{
	background-image: linear-gradient(270deg, #987087 0%, #987087 100%);
}

.app-btn-1.creditDisabled:active{
	scale: 1;
}

.app-btn-1.creditDisabled:hover{
	cursor: default;
	background-image: linear-gradient(270deg, #987087 0%, #987087 100%);
}

.app-btn-1.creditScore{
	min-width: max-content;
	flex: 1 1 0px;
}

.app-btn-1-reverse.creditScore{
	min-width: max-content;
	flex: 1 1 0px;
}

@media (max-width:600px) {
	/* .app-btn-1-reverse.creditScore{
		min-width: 0px;
	} */
	.creditScore__buttons{
		padding: 1rem 2rem;
	}
}

.useCredits__chapters{
	padding-block-start: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	
	width: 90%;
	max-height: 260px;
	overflow: auto;
}

.useCredits__chapters::-webkit-scrollbar {
    display: none;
}

.useCredit__ChapterDisp{
	padding: 0.8rem 1rem;
	padding-inline-end: 1rem;
	
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	
	align-items: center;
	background-color: var(--fg-secondary-clr);
	border-radius: var(--border-6);
	box-sizing: border-box;
}

.useCredit__ChapterDisp.chap-selected{
	border: 2px solid #2f99d7;
}
.useCredit__ChapterDispLeft{
	display: flex;
	gap: 1rem;
	
	align-items: center;
}

.usecredit__chapterDispImg{
	width: 52px;
    height: 52px;
}

.usecredit__chapterDispName{
	font-size: var(--font-size-lg);
	font-weight: 600;
}

.useCredit__seperation{
	background-image: linear-gradient(90deg, #7F27A8 0%, #EB21A0 100%);
	height: 4px;
	width: 100%;
}

.useCredits__BottomContainer{
	width: 100%;
	padding: 0;
	
	border-bottom-left-radius: var(--border-6);
	border-bottom-right-radius: var(--border-6);
	
	background-color: var(--bg-primary-clr);
}

@media (max-width:600px) {
	.useCredits__BottomChapters{
		font-size: small;
	}
}

.useCredits__Bottom{
	padding: 0.5rem 2rem;
	display: flex;
	gap: 1rem;
	flex-direction: row-reverse;
	align-items: center;
}

@media (max-width:600px) {
	.useCredits__Bottom{
		padding: 0.5rem;
	}
	.useCredits__BottomButton .app-btn-1{
		font-size: 12px;
		padding: 10px 40px;
		min-width: max-content;
		width: auto;
	}
}

.closebuttonForPremiumModal:hover {
	cursor: pointer;
}

.BuyNowBtnForPremiumModal.app-btn-1{
	@media(width<600px){
		width: 100%;
	}
}
/* //////////////////////////////////////////////////////////////// */

/* .BuyNowBtnForPremiumModal {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    padding: 14px 32px;
    gap: 10px;
    width: 200px;
    background: linear-gradient(270deg, #f8219f 0%, #7b28a9 100%);
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;

    color: #f7f7fc;
} */


