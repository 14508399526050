.service-section {
    min-height: 50vh;
    text-align: center;
    padding-block: 6em;
    font-family: 'poppins';
    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;
    padding: 1em;
}

.service-section_title {
    color: var(--text-primary-clr);
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 1.2px;
    margin-block: 2.5em;
}

.service-section__inner {
}

.service-section__inner__title {
    font-size: var(--font-size-4lg);
    font-weight: 700;
    letter-spacing: 1.2px;
    color: var(--stroke-primary-clr);
    margin-block-end: 1em;
}

.service-section__inner__sub-title {
    font-size: var(--font-size-2lg);
    color: var(--text-secondary-clr);
    letter-spacing: 1.2px;
    line-height: 1.2;
}

.service-section__inner__img1 {
    margin-inline: auto;
    margin-block: 3em;
    width: 100%;
    max-width: 400px;
}

.service-section__inner__img2 {
    margin-inline: auto;
    margin-block: 3em;
    width: 100%;
    max-width: 600px;
}

