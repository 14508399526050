.pricing-policy__info-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-block: 1em;
}

@media (width > 1024px) {
    .pricing-policy__info-container {
        display: flex;
        gap: 4em;
    }
}

.pricing-policy__info-content__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    line-height: 1.2;
    margin-block: 1em;
}

.pricing-policy__info-content__container {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    gap: .25em;
}

.pricing-policy__info-content__container-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    width: 100px;
}

.pricing-policy__info-content__container-pricing {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    width: max-content;
}

.pricing-policy__info-content__container-pricing.strikethrough {
    text-decoration: line-through;
}

.pricing-policy__info-content__container-title,
.pricing-policy__info-content__container-pricing {
    justify-items: center;
    align-content: center;
}

.pricing-policy__info-content_horizontal-line {
    height: 3px;
    width: 100%;
    background-color: var(--accent-primary-clr);
    margin-block: .5em;
}

.pricing-policy__info-content__pricing {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--accent-primary-clr);
}

.misc {
    padding-block-start: 8em;
    padding-inline: 1em;
    color: var(--text-primary-clr);
    font-family: 'Poppins';
    font-style: normal;
    letter-spacing: 0.75px;
}

@media (width > 600px) {
    .misc { 
        width: 100%;
        max-width: var(--app-width);
        margin-inline: auto;
    }
}

.misc-inner-container {
    display: flex;
    align-items: flex-end;
    gap: 1em;
    padding-block-end: 1em;
}

.misc-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
}

.misc-bold-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;

    margin-block-start: 1em;
}

.misc-updated-date {
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
    color: var(--accent-primary-clr);
    translate: 0 4px
}

.misc-sub-title {
    padding-block: 1em;

    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: var(--text-secondary-clr);
}

.misc-sub-title.underline { 
    text-decoration-line: underline;
}

.misc-paragraph-container {
    margin-block-end: 1em;
}

.misc__paragraph-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.misc__paragraph {
   color: var(--text-secondary-clr);
}  

.misc-footnote {
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.75px;
    text-decoration-line: underline;
}