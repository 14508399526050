.chapter-reviews {
    padding-block-start: 5em;
    padding-inline: 1em;
    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;
}

/* .stepper-container {
    display: flex;
    align-items: center;
} */