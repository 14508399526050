.preloader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  background: black;
}

.preloader__img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
