.fullNameAndMobileNoContainer {
    display: flex;
    flex-direction: column;
    gap: 0.7em;
	padding: 0 1em;
}

.fullNameAndEditBtn,
.mobileNumAndEditBtn {
    display: flex;
    align-items: center;
    gap: .5em;

    font-weight: 400;
    color: var(--text-primary-clr);
}

.profileName {
    font-weight: 600;
    letter-spacing: 1px;
}

.InputDivForProfile {
    max-width: 150px;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: transparent;
    border-bottom: 2px solid var(--stroke-primary-clr);
    padding-inline: .5em;
    caret-color: var(--stroke-primary-clr);
}

.InputDivForProfile:focus {
    outline: transparent;
}

@media (min-width: 500px) {

    .fullNameTitle {
        display: block;
        font-size: var(--font-size-3lg);
    }

    .fullNameTitle{
        font-size: var(--font-size-24);
    }
}

.fullNameAndMobileNo__editBtn{
	width: 1em;
}

.fullNameAndMobileNo__editBtn:hover{
	cursor: pointer;
}