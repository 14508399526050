.blog-body-item  {
    padding-block: .5em;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.25px;
}

.blog-paragraph {

}

.blog-number-list {
    padding-inline: .5em;
}

.blog-number-list__item {
    margin-block-end: 1em;
}
.blog-number-list__item.no-margin {
    margin-block-end: revert;
}

.blog-number-list__title {
    color: var(--accent-primary-clr);
    font-weight: 600;
}

.blog-number-list__text {
    display: inline;
    margin-inline-start: .5em;
}

.blog-quote {
    font-weight: 600;
    padding-inline: 1em;
}

.blog-bullet-list {
    padding-inline: .5em;
}

.blog-bullet-list__bullet {
    display: inline-block;
    width: 10px;
    aspect-ratio: 1/1;
    vertical-align: middle;
    border-radius: 50%;
    margin-inline-end: .5em;
    background-color: var(--accent-primary-clr);
}