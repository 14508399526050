.blog-page-container {
    /* background: linear-gradient(#f3219f -98%,#08091c00 55%); */
    background: linear-gradient(#f3219f61 0%, #08091c00 15%);
}


.blog-page {
    padding-block-start: 8em;
    padding-inline: 1em;
    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;
    font-family: 'poppins';
    color: var(--text-primary-clr);
    letter-spacing: 1px;

    /* background-image: linear-gradient(
        #7b28a9 0%,
        #f8219f 93%,
        rgba(0, 0, 0, 0) 100%
      ); */

}

.blog-page__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .5em;
}

@media (width > 1030px) {
    .blog-page__header {
        flex-direction: row;
        gap: 1em;
    }
}

.blog-page__header-left {
    display: flex;
    align-items: center;
    gap: .5em;
}

.blog-page__header-title {
    font-weight: 700;
    font-size: var(--font-size-48);
}

.blog-page__header-search-container {
    height: 45px;
    display: flex;
    align-items: center;
    gap: 1em;
    border: 2px solid var(--stroke-primary-clr);
    border-radius: var(--border-10);
    padding-inline: .5em;
    width: 100%;
    max-width: 449px;
    background: var(--bg-secondary-clr);
}

@media (width > 1030px) {
    .blog-page__header-search-container {
        min-width: 449px;
    }
}

.blog-page__header-search-icon {
    width: 20px;
    aspect-ratio: 1/1;
}

.blog-page__header-search-input {
    width: 100%;
    caret-color: var(--stroke-primary-clr);
    background-color: transparent;
}

.blog-page__header-search-input:focus {
    outline: transparent;
}

.blog-page__header-tags-container {
    display: flex;
    gap: .5em;
}

.blog-page__header-tag {
    width: max-content;
    height: max-content;
    padding-inline: 1em;
    padding-block: .5em;
    background: rgb(57, 57, 57);
    border: 2px solid rgb(112, 111, 111);
    border-radius: var(--border-8);
}

.blog-page__header-tag:active {
    scale: .98;
}

.blog-page__header-tag.green {
    background: #146f3966;
    border: 2px solid #146F39;
}

.blog-page__header-tag.blue {
    background: rgba(20, 89, 111, 0.4);
    border: 2px solid #14596F;
}

.blog-page__header-tag.red {
    background: rgba(111, 20, 20, 0.4);
    border: 2px solid #6F1414;
}

.blog-page__header-tag.orange {
    background: rgba(111, 91, 20, 0.4);
    border: 2px solid #6F5B14;
}

.blog-page__filter-indicator {
    margin-block-start: 1em;
}

.blog-page__filter-active {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* animation: feedback 1s ease-in forwards; */
}

/* @keyframes feedback {
    from {
        scale: .98;
    }
    to {
        scale: 1.2
    }
} */

.blog-page__horizontal-line {
    height: 4px;
    width: 100%;
    background-image: linear-gradient(
        90deg,
        #7b28a9 0%,
        #f8219f 93%,
        rgba(0, 0, 0, 0) 100%
      );
}

.blog-page__articles-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    margin-block-start: 1em;
}

@media (width < 700px) {
    .blog-page__articles-container {
        grid-template-columns: 1fr;
    }
}

.blog-page__related-articles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    margin-block-start: 1em;
}
