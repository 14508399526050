.tutorial-tooltip{
    position: fixed;
    /* left: 50%; */
    translate: -50% 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;

    padding: 18px;
    background-color: var(--fg-tertiary-clr);
    color: var(--text-primary-clr);
    border: 3px solid var(--icon-primary-clr);
    border-radius: var(--border-8);

    width: 350px;


    font-family: 'poppins';
    z-index: 20;

    @media (width > 600px) {
        width: 471px;
        /* height: 344px; */
   }

   animation: fade-in 500ms ease-in ;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.tutorial-tooltip__corner {
    position: absolute;
    height: 25px;
    width: 25px;
    background: var(--stroke-primary-clr);
}

.tutorial-tooltip__corner::before {
    content: '';
    position: absolute;
    height: 60%;
    width: 100%;
    clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%);
    background-color: var(--fg-tertiary-clr);
  }

  /* step-1  */

.tutorial-tooltip.step-1 {
    @media (width > 1200px) {
        border-radius: 10px;
        overflow: hidden;
        border-top-left-radius: 0
    }
    
    @media (width > 1200px) and (height < 750px){
        border-radius: 10px;
        border-bottom-left-radius: 0
    }


}

.tutorial-tooltip__corner.step-1{
    bottom: -16px;
    left: 50%;
    translate: -50% 0;
    rotate: 225deg;

    @media (width > 600px) {
        bottom: revert;
        top: -15px;
        rotate: 45deg;
    }

    @media (width > 1200px) {
        top: -12px;
        left: -2px;
        rotate: 135deg;
    }

    @media (height < 750px){
        top: revert;
        bottom: -16px;
        left: 50%;
        translate: -50% 0;
        rotate: 225deg;
    }

    @media (width > 1200px) and (height < 750px) {
        inset: revert;
        bottom: -15px;
        left: 0;
        rotate: 45deg;
    }
}

.tutorial-tooltip__corner.step-1::before{
    rotate: 135deg;
    top: 7.5px;
    width: 50px;

    @media (width > 1200px) {
        background-color: red;
    }
}

    /* step-2 */

.tutorial-tooltip.step-2 {
    border-radius: 10px;
    @media (width > 1200px) {
        overflow: hidden;
        border-bottom-left-radius: 0
    }
}

.tutorial-tooltip__corner.step-2 {
    bottom: -15px;
    left: 50%;
    translate: -50% 0;
    rotate: 225deg;

    @media (width > 600px) {
        bottom: revert;
        top: -15px;
        rotate: 45deg;
    }

    @media (width > 1200px) {
        top: revert;
        bottom: -15px;
        left: 0;
        rotate: 45deg;
    }

    @media (height < 750px) {
        inset: revert;
        bottom: -15px;
        left: 50%;
        translate: -50% 0;
        rotate: 225deg;
    }

    @media (width > 1200px) and (height < 750px) {
        inset: revert;
        bottom: -15px;
        left: 0;
        rotate: 45deg;
    }
}
    
.tutorial-tooltip__corner.step-2::before {
    rotate: 135deg;
    top: 7.5px;
    width: 50px;

    @media (width > 1024px) {
        /* background-color: red; */
    }
}

     /* step-3 */

.tutorial-tooltip.step-3 {
    border-radius: 10px;

    @media (width > 600px) {
        overflow: hidden;
        border-top-left-radius: 0
    }

    @media (height < 750px) and ( width > 600px){
        border-radius: 10px;
        border-bottom-left-radius: 0
    }
}

.tutorial-tooltip__corner.step-3 {
    bottom: -15px;
    left: 50%;
    translate: -50% 0;
    rotate: 225deg;

    @media (width > 600px) {
        bottom: revert;
        top: -15px;
        left: 0;
    }

    @media (height < 750px) and ( width > 600px) {
        top: revert;
        bottom: -15px;
        rotate: 45deg;
    }
}
    
.tutorial-tooltip__corner.step-3::before {
    rotate: 135deg;
    top: 7px;
    width: 50px;
}

.tutorial-tooltip__inner-container {
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.tutorial-tooltip__step-no {
    font-size: var( --font-size-md);
    margin-block-end: .25em;
}

.tutorial-tooltip__title {
    font-size: var( --font-size-lg);
    font-weight: 700;
    line-height: 33px;
    /* margin-block-end: .25em; */

    @media (width > 600px) {
        font-size: 22px;
        /* margin-block-end: .5em; */
    }

}

.tutorial-tooltip__subtitle {
    font-size: var( --font-size-md);
    color: var(--text-secondary-clr);
    line-height: 27px;
    @media (width > 600px) {
        font-size: var( --font-size-lg);
    }
}

.tutorial-tooltip__btn {
    padding: 14px 40px;
    border-radius: var(--border-8);
    align-self: center;
    font-size: var(--font-size-md);
    background: linear-gradient(270deg, #F8219F 0%, #7B28A9 100%);

    @media (width > 1200px) {
        padding: 14px 32px;
        align-self: flex-end;
    }
}


.tutorial-tooltip__option-container {
    margin-block-start: .25em;
}

.tutorial-tooltip__options {
    display: flex;
    gap: 1em;
    width: 100%;
}

.tutorial-tooltip__options-text-1,
.tutorial-tooltip__options-text-2 {
    font-size: var(--font-size-lg);
    line-height: normal;
    width: 100%;
}

.tutorial-tooltip__options-text-1 {
    width: max-content;
    /* max-width: 250px; */
}

.tutorial-tooltip__options-text-2 {
    color: var(--text-secondary-clr);
    font-weight: 400;
}

/* custom tooltip */

.tutorial-tooltip.custom {
    position: absolute;
    top: -10px;
    left: 300px;
}

.tutorial-tooltip.custom.small {
    top: -300px;
    left: 50%;
    translate: -50% 0;

    width: 100%;
    max-width: 350px;

    @media (width > 600px) {
        display: none
    }
}

.tutorial-tooltip.custom.big {

    flex-shrink: 0;
    top: 30px;
    left: 310px;

    @media (width < 600px) {
        display: none
    }

    @media (height < 750px) {
        top: revert;
        bottom: 35px;
        translate: -50% 0;
    }
}