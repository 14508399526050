

.blog-widget{
	position: sticky;
	position: -webkit-sticky;
	top: 8.5rem;

	width: 28rem;
	min-width: 16rem;

	border-radius: var(--border-6);
	background: var(--fg-secondary-clr);
	/* min-width: min-content; */
	
	padding-block-end: .1rem;
}

@media (width < 1024px) {
	.blog-widget{ 
	display: none;
	}
}

.blog-widget__heading{
	padding-block: 1rem;
	
	font-weight: 600;
	font-size: var(--font-size-3lg);
	text-align: center;
}

.blog-widget__contents{
	margin-block-end: 1.5rem;
	margin-inline: 1.5rem;
	padding: 0.7rem;

	background: var(--fg-primary-clr);
	border-radius: var(--border-8);
	
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
	
	max-width: 100%;
}

.blog-widget__img-container {
	flex-shrink: 0;
	width: 180px;
	border-radius: var(--border-6);
	overflow: hidden;
	background-color: green;
}

.blog-widget__img{
	width: 100%;
	transition: all .3s ease;
}


.blog-widget__img:hover{
	scale: 1.15;
}

.blog-widget__text{
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.blog-widget__text-title{
	font-size: var(--font-size-sm);
	font-weight: 600;
}

.blog-widget__text-content{
	font-size: var(--font-size-xs);
	color: var(--text-secondary-clr);
}

.blog-widget__date,
.blog-widget__read-time {
font-size: 14px;
}

.blog-widget__seperator-dot {
	display: inline-block;
	width: 8px;
	aspect-ratio: 1/1;
	margin-inline: .5em;
	border-radius: 50%;
	background-color: var(--accent-primary-clr);
}

