.backdrop {
  position: fixed;
  inset: 0;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  z-index: 10;
}

.backdrop.no-header {
  margin-top: 6em;
}

/* .backdrop.bg-blur {
  backdrop-filter: blur(2px);
} */

 .backdrop_section.header-section {
  height: 6em;
}

 .backdrop_section.top-section {
  height: 18em;
}

.backdrop.no-header  .backdrop_section.top-section {
  height: 10em;
}

/* .backdrop_section.mid-section  {
  height: 25%;
} */

 .backdrop_section.bottom-section {
  height: 40%;
}

.backdrop_section.bg-blur {
  backdrop-filter: blur(2px);
}



