.error-page {
    /* padding-block-start: 8em;
    padding-inline: 1em; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    color: var(--text-primary-clr);
    font-family: 'Poppins';
    font-style: normal;
}

.error-page-container {
    text-align: center;
    letter-spacing: 0.75px;
}

.error-page__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
}

.error-page__sub-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--text-secondary-clr);
    margin-block: 1em;
}

.error-page-btn {
    margin-inline: auto;
}
