.footer {
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: var(--bg-primary-clr);
    padding-inline: 1rem;
    padding-block: 2em ;

    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.25);

    @media (width > 900px) {
        flex-direction: row;
        gap: 5%;
        width: 100%;
        max-width: var(--app-width);
        margin-inline: auto;
    }
}

/* delete this when you can use css nesting on firefox and safari */

@media (width > 900px) {
    .footer {
        flex-direction: row;
        gap: 5%;
        width: 100%;
        max-width: var(--app-width);
        margin-inline: auto;
}  
}

.section-container {
    flex: 1;
    flex-direction: column;
    width: 100%;
    display: flex;
    gap: 1em;
    justify-content: space-evenly;

    @media (width > 600px) {
        flex-direction: row;
    }

    @media (width > 900px) {
        justify-content: space-between;
    }
}

/* delete this when you can use css nesting on firefox and safari */

@media (width > 600px) {
    .section-container {
        flex-direction: row;
    }
}

@media (width > 900px) {
    .section-container {
        justify-content: space-between;
    }
}


.footer__inner-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5em;
} 

.title {
    margin-block: .75em ;
    font-family: 'poppins';
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: var(--accent-primary-clr);
}

.subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--text-secondary-clr);
}

.footer__social-media {
    display: flex;
    gap: 1em;
    margin-block-start: 1em;
}

.section.newsletter {
    padding-inline: 1em;

    @media (width > 600px) {
        margin-inline: auto
    }

    @media (width > 900px) {
        padding-inline: 0;
    }
}


/* delete this when you can use css nesting on firefox and safari */

@media (width > 600px) {
    .section.newsletter {
        margin-inline: auto
    }
}

@media (width > 900px) {
    .section.newsletter {
        padding-inline: 0;
    }
}

.newsletter .footer__inner-container {
    display: flex;
    @media (width > 600px) {
        flex-direction: row;
        align-items: stretch;
    }
}

@media (width > 600px) {
    .newsletter .footer__inner-container {
        flex-direction: row;
        align-items: stretch;
    }
}

.newsletter-subscribe {
    color: var(--text-primary-clr);
    font-size: 24px;
}

.footer__newsletter__input {
    width: 100%;
    border-radius: var(--border-8);
    padding: .4em;
    color: var(--bg-primary-clr);
    @media (width > 600px) {
        width: 360px
    } 
}

.newsletter-subscribe {
    color: var(--text-primary-clr);
}

.newsletter-subscribe-btn {
    margin-block-start: 1em;
    width: 100%;
    margin-inline: auto;

   @media (width > 600px) {
        margin-block-start: 0;
        margin-inline: revert;
   }

   @media (min-width: 700px) {
        margin-block-start: 0;
   }

   @media (min-width: 1024px) {
        margin-inline: revert;
        margin-block-start: 0;
  }

}

@media (width > 600px) {
    .newsletter-subscribe-btn { 
        margin-block-start: 0;
        margin-inline: revert; 
    }
}

@media (min-width: 700px) {
    .newsletter-subscribe-btn { 
        margin-block-start: 0;
    }
}

@media (min-width: 1024px) {
    .newsletter-subscribe-btn { 
        margin-inline: revert;
        margin-block-start: 0;
    }
}