.chapter-practice__title {
    font-weight: 600;
    font-size: var(--font-size-4lg);
    color: var(--text-primary-clr);
    flex-shrink: 0;
}

/* .stepOneContainer {
    position: relative;
    background-color: green;
} */