
.progress-bar-chart {
    position: relative;;
    background-color: var(--fg-primary-clr);
    width: 100%;
    max-width: var(--app-width);
    padding-block: 3em;
    padding-inline: 1em;
    margin-inline: auto;
    border-radius: var(--border-8);
}

.progress-bar-chart__outer-container {
    display: flex;
    justify-content: center;
}

@media (width > 900px) {
    .progress-bar-chart__outer-container {
        flex-direction: column;
    } 
}

.progress-bar-chart__steps-container {
   display: flex; 
   flex-direction: column;
   justify-content: space-between;
   padding-inline: 1em;
}

@media (width > 900px) {
    .progress-bar-chart__steps-container {
        flex-direction: row;
    } 
}

.progress-bar-chart__steps {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.progress-bar-chart__steps.first {
    width: revert;
}

.progress-bar-chart__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
}

@media (width > 900px) {
    .progress-bar-chart__container {
        flex-direction: row;
    }
}

.category-seperator {
    height: 2em;
    width: 3px;

    background-color: var(--accent-primary-clr);
}

@media (width > 900px) {
    .category-seperator {
        height: 3px;
        width: 100%;
        min-width: 2em;
        background-color: var(--accent-primary-clr);
    }
}

.progress-bar-chart__step-no {
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;

    border-radius: 50%;
    background-color: var(--accent-primary-clr);
    color: var(--text-primary-clr);
    font-weight: 700;
    font-size: var(--font-size-2lg);
    transition: all .5s ease;
    z-index: 2;
}

.progress-bar-chart__category-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.progress-bar-chart__category {
    position: relative;
}

@media (width > 900px) {
    .progress-bar-chart__category-container {
        flex-direction: row;
        margin-block-start: 2em;
    } 
}

.progress-bar-chart__category-box {
    width: 60px;
    height: 40px;
    border-radius: var(--border-10);
}

.progress-bar-chart__bar {
    position: relative;

    height: 40px;
    width: 100%;
    max-width: 900px;
    border-radius: var(--border-10);
    margin-inline: auto;
    margin-block-start: 4em;
    background: rgba(248, 33, 159, 0.2);
}

.progress-bar-chart__bar-inner-bar {
    height: 100%;
    border-radius: var(--border-10);
}

.progress-bar-chart__progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    color: var(--text-primary-clr);
    /* right: .5em; */

    font-weight: 700;
    z-index: 2;

    width: max-content;
}

.progressChartTimeRemains {
    text-align: center;
    padding-top: .5em;
    color: var(--text-secondary-clr)
}

.lock-in-memory {
    position: absolute;
    top: 3em;
    left: 4em;
    min-width: 300px;
    width: 100%;
    padding: .5em;
    color: var(--text-primary-clr);
    background-color: var(--fg-tertiary-clr);
    border: 3px solid var(--stroke-primary-clr);
    border-radius: var(--border-8);
    border-top-left-radius: 0;
    z-index: 20;
    overflow: hidden;
}

/* last box */

.lock-in-memory.last-box  {
    border-radius: var(--border-8);
    border-top-right-radius: 0;
    left: revert;
    right: 4em;
}

.lock-in-memory__title {
    font-size: var(--font-size-22px);
    font-weight: 700;
}

.lock-in-memory__sub-title {
    color: var(--text-secondary-clr);
}

.category-colored-box {
    width: 70px;
    min-width: 50px;
    height: 25px;
    transition: all .5s ease;
}

.lock-in-memory__corner {
    position: absolute;
    height: 25px;
    width: 25px;
    top: -12px;
    left: -12px;
    transform: rotate(45deg);
    background: var(--stroke-primary-clr);
}

.lock-in-memory.last-box .lock-in-memory__corner  {
    left: revert;
    right: -12px;
 }

@media (min-width: 600px) {

    .progress-bar-chart__step-no {
        width: 40px;
        height: 40px;
    }

    .category-colored-box {
        width: 70px;
        height: 40px;
    }
    
}

.infoIcon__container {
    position: relative;
}

.infoIcon {
    margin-block: 1em;
    margin-inline: auto;

    @media (width > 600px) {
        display: none
    }
}