
/* testimonials styles */

.testimonials {
    text-align: center;
    background-color: inherit;
    padding-inline: 1em;
    padding-block: 6em;
 }
 
 .tesitmonials__title {
     color: var(--text-primary-clr);
     font-size: 40px;
     font-weight: 700;
     letter-spacing: 1.2px;
     margin-block-end: 1em;

    @media (width < 500px) {
        font-size: var(--font-size-2lg);
    }
 }

 .tesitmonials__swiper {
    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;
 }

 .swiper-slide {
    height: 480px;

    @media (width > 1024px) {
        height: 100%;
    }
 }

 .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    padding: .5em;
  }

.swiper-pagination-bullet {
    background-color: #cecdcd
  }

 .swiper-pagination-bullet-active {
    background-color: var(--accent-primary-clr);
  }

.testimonial__card {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    padding: 3.5em;
    max-width: 400px;
    margin-inline: auto;
    flex: 1;
    background-color: var(--fg-primary-clr);
    border-radius: var(--border-10);

    @media (width > 1024px) {
        height: 100%;
    }
}

.testimonial__card__comment {
    color: var(--text-secondary-clr);
    width: 95%;
    /* background-color: green; */
    min-height: 100px;
    font-size: 22px;
    line-height: 33px;
}

.testimonial__card__pic {
    width: 158px;
    height: 158px;
}

.testimonial__card__name {
    font-weight: 600;
    font-size: 20px;
}

