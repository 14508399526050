.stepper-box {
    display: flex;
    align-items: center;
    margin-block: 1.5rem;
	border-radius: var(--border-10);
    background: #100c2a;
    width: 100%;
}

@media (width > 600px) {
    .stepper-box {
        background: none;
    }
}

.stepper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-inline: auto;
    border-radius: var(--border-10);
    background-color: transparent;
}

.stepper-container__small {
    border-radius: var(--border-10);
    padding-block: 2em;
}

.stepper-container-for-mobile {
    padding-inline: .5em;
    /* @media (width > 600px) {
        display: none;
    } */
}

@media (width > 600px) {
    .stepper-container-for-mobile {
        display: none;
    }
}

.stepper-container__big {
    width: 100%;
    padding-block: .25em;
    padding-inline: 2em;
    border-radius: var(--border-10);
    height: 100%;
    background-color: #100c2a;

    /* @media (width < 600px) {
        display: none;
    } */
}

@media (width < 600px) {
    .stepper-container__big { 
        display: none;
    }
}

.stepper-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
}

.stepper-item {
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;
    z-index: 1;
    width: 100%;
}

.stepper-item.first-item {
    width: revert;
}

.stepper-seperator {
    width: 100%;
    height: 4px;
    background: rgba(248, 33, 159, 0.4);
}

.stepper-seperator.past {
    background: rgba(123, 40, 169, 0.4);
}

.step {
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: wheat;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: var(--text-font-md);

    letter-spacing: 0.75px;

    color: #f9f9f9;

    @media (width < 600px) {
        display: none
    }
}

@media (width < 600px) {
    .step {
        display: none
    }
}

.current {
    width: 35px;
    height: 35px;
    border: 0.2rem solid #f8219f;
}

.step-label {
    font-weight: bold;
    margin-bottom: 10px;
}

.stepper__in-between-steps-line {
    position: absolute;
    top: 50%;
    width: 100%;
    height:4px;
    background-color: var(--accent-primary-clr);

    @media (width < 600px) {
        display: none
    }
}

@media (width < 600px) {
    .stepper__in-between-steps-line { 
        display: none
    }

}

.sideLine {
    z-index: 0;
    width: 100%;
    height: 2px;
}

.stepper__seperator {
    height: 20px;
    width: 100%;
    background-color: var(--accent-primary-clr);
}

.quickRevisionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.quickRevisionText {
    flex-shrink: 0;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: var(--text-font-md);
    letter-spacing: 0.75px;
    
    background-color: #100c2a;
    color: var(--text-primary-clr);
}

.curr-stepper {
    background-color: #7b28a9;
}

.remaining-stepper {
    background-color: #f8219f;
}

.ended-stepper {
    background-color: #7b28a9;
}

.sideLine {
    /* width: 3em; */
    height: 0.3vw;
}

@media screen and (max-width: 1000px) {
    /* .sideLine {
        width: 8vw;
        height: 0.3vw;
    } */

    /* .step {
        width: 3vw;
        height: 3vw;
        font-size: 1.3vw;
    }

    .curr-stepper {
        width: 3.5vw;
        height: 3.5vw;
        font-size: 1.5vw;
    }

    .quickRevisionText {
        font-size: 1.3vw;
    } */
}


@media screen and (max-width: 600px) {
    .curr-stepper {
        
        position: absolute;
        left: 47.5%;
		top: -50%;

        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: #7b28a9;
        border: 2px solid #f8219f;

        padding: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.75px;

        color: #f9f9f9;


        translate: 0 7px;

    }

    .stepper-container-for-mobile {
        position: absolute;
        top: 37%;
        height: 45%;
        right: .5em;
        left: .5em;

        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        /* width: 100vw; */

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @media (width > 600px) {
            display: none
        }
    }

    .remaining-stepper,
    .ended-stepper {
        padding: 0.1rem 0.6rem;

        border-radius: 1.2rem;

        text-align: center;
        background: #f8219f;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;

        letter-spacing: 0.75px;

        color: #f9f9f9;
        position: absolute;
        right: 1.5em;
        translate: 0 -4px;
    }

    .ended-stepper {
        background: #7b28a9;
        padding: 0.1rem 0.7rem;
        position: absolute;
        right: auto;
        left: 1.5em;
    }

	.first-ques{
		background-image: url("../../../assets/images/Second.svg");
	}

	.middle-ques{
		background-image: url("../../../assets/images/Question.svg");
	}

	.last-ques{
		background-image: url("../../../assets/images/First.svg");
	}
}
