.searchBox {
  display: flex;
  align-items: center;
  gap: 0.25em;

  padding-block: 0.25em;
  border-radius: 5px;
  width: 100%;

  transition: all 0.2s ease-in;
  border: 1px solid transparent;
}

.searchBox.flip-direction {
  flex-direction: row-reverse;
}

.searchBox.clicked {
  padding-inline: 0.25em;
  width: 100%;
  border: 1px solid var(--stroke-primary-clr);
}

.searchIcon {
  width: 80%;
  height: 80%;
}

.searchBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 1s ease;
  flex-shrink: 0;
}

.searchField {
  background-color: transparent;
  outline: transparent;
  color: white;
  font-size: small;
  width: 100%;
}

.searchField:focus {
  outline: transparent;
}

@media (max-width: 600px) {
  .searchBox {
    flex-direction: row-reverse;
    margin-inline-end: 0.25em;
  }
}

@media (min-width: 600px) {
  .searchBox.clicked {
    width: 250px;
  }
}
