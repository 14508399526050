.self-evaluation {
    position: fixed;
    top: 4em;
    left: 1em;
    right: 1em;

    padding: 2em;
    background-color: var( --bg-primary-clr);
    border-radius: var(--border-10);

    font-family: 'Poppins';
    font-style: normal;
    letter-spacing: 1px;
    color: var(--text-primary-clr);

    overflow: scroll;
    height: 100vh;
    z-index: 20;
}

.self-evaluation::-webkit-scrollbar {
    display: none;
  }

@media (width > 600px) {
    .self-evaluation {
        top: 50%;
        translate: 0 -50%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: auto;

        background-color: var( --bg-secondary-clr);

        max-width: var(--app-width);
        margin-inline: auto;
    }
}

.self-evaluation__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    margin-block-end: .5em;
    text-align: center;
}

.self-evaluation__sub-title {
    width: 100%;
    max-width: 500px;
    margin-inline: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--text-secondary-clr);
    text-align: center;
}

/* evaluation-card */

.self-evaluation__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;

    margin-block-start: 1.5em;
}

@media (width > 600px) {
    .self-evaluation__cards {
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: 1fr 1fr;
        justify-items: center;
    }
}


@media (width > 1024px) {
    .self-evaluation__cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: revert;
    }
}


.evaluation-card {
    display: flex;
    align-items: center;
    gap: 1em;

    padding: 1em;
    width: 100%;
    max-width: 300px;

    background-color: var( --fg-primary-clr);

    border: 2px solid var(--stroke-primary-clr);
    border-radius: var(--border-6) ;

    cursor: pointer;
}

@media (width > 600px) {
    .evaluation-card{
        flex-direction: column;
    }
}

.evaluation-card__title {
    width: max-content;
}