.toast-div {
	position: fixed;
	z-index: 30;
	left: 50%;
	translate: -50%;
	top: 5.5rem;
	padding: 0.5rem 2rem;
	visibility: hidden;
	
	display: flex;
	gap: 1rem;

	border: 2px solid;
	border-radius: var(--border-8);

	font-family: 'Poppins';
	text-align: center;
	font-weight: 400;
	color: var(--text-primary-clr);
	background-color: var(--bg-primary-clr);
  }
 
 .toast-div.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 1.5s;
	animation: fadein 0.5s, fadeout 0.5s 1.5s;
  }
 
  @-webkit-keyframes fadein {
	from {top: 3rem; opacity: 0;}
	to {top: 5.5rem; opacity: 1;}
  }
  
  @keyframes fadein {
	from {top: 3rem; opacity: 0;}
	to {top: 5.5rem; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
	from {top: 5.5rem; opacity: 1;}
	to {top: 3rem; opacity: 0;}
  }
  
  @keyframes fadeout {
	from {top: 5.5rem; opacity: 1;}
	to {top: 3rem; opacity: 0;}
  }