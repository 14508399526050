
/* deckComplete styles */

.deckComplete {
    margin-block: 8em;
    background: var(--bg-primary-clr);
    font-family: "Poppins";
    letter-spacing: 0.75px;
    margin-inline: 1em;
}

/* deckComplete__continueBtn styles */ 

.continue {
    display: flex;
    justify-content: center;
    margin-block: 2em;
}

.continue-btn {
    /* background: linear-gradient(90deg, #7b28a9 11.46%, #f8219f 77.16%);
    padding: 14px 24px;
    font-weight: 600;
    font-size: var(--font-size-md);
    letter-spacing: inherit;
    color: var(--text-primary-clr);
    border-radius: var(--border-8); */
}


/* chapter complete styles */


/* completeHeader styles */

.completeHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;

    text-align: center;
}

.completeHeaderTitle {
    font-size: var(--font-size-3lg);
    color: var( --text-primary-clr);
    font-weight: 700;
}

.completeHeaderSubTitle {
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.75px;
    line-height: var(--line-height-small);
    font-size: var(--font-size-lg);
    color: var( --text-secondary-clr);

    width: 100%;
    max-width: 550px;
}

/* chapterDropDown styles */

.chapterDropDownContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 1.5em;
}

.chapterDropDown {
    position: relative;
    color: var(--text-primary-clr);
}


.completeChapterImage {
    width: 55px;
    height: 55px
}

.completeChapterName {
    font-weight: 700;
    font-size: var(--font-size-lg);
}

.completeChapterDropdown {
    transition: all .5s ease-in-out
}

.completeChapterDropdown.up {
    transform: rotate(90);
}

/* completeChapterList styles */

.completeChapterList {
    position: absolute;
    /* inset: -2%; */
    top: 3em;
    left: -20%;
    right: -20%;

    overflow-x: hidden;
    padding: 1em;

    border: 4px solid var(--stroke-primary-clr);
    border-radius: var(--border-8);

    background-color: var(--fg-primary-clr);
    z-index: 10;

    transition: right .5s ease;
}

.completeChapterList::-webkit-scrollbar {
    display: none;
}

.completeChapterListTitle {
 color: var(--text-primary-clr);
 font-size: var(--font-size-lg);
 font-weight: 700;
}

.completeChapterListChapter {
    color: var(--text-primary-clr);
    margin-block: 1em;
}


/* completeProgressChart styles */

.progressChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 450px;
    height: 450px;
    margin-inline: auto;
    background: var(--fg-primary-clr);
    border-radius: 10px;
}

.progressChartPercentage {
    position: relative;
    top: -55%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
    width: 55px;
    margin-inline: auto;

    font-weight: 700;
    font-size: var(--font-size-3lg);
    text-align: center;
    letter-spacing: 0.75px;
    
    color: var(--text-primary-clr);
}

.progressChartPercentage__text {
    font-weight: 400;
    font-size: var(--font-size-md);
}

.progressChartTimeRemains {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #f9f9f9;
    padding-top: 7.5%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    width: 100%;
}

.timerAndQuesNoIconForDeck {
    width: 18px;
    height: 18px;
    translate: 0 -2px;
}


@media (min-width: 500px) {
    .completeHeaderTitle {
        font-size: var(--font-size-48);
    }
    .completeChapterName {
        font-size: var(--font-size-5lg);
    }

}

@media (min-width: 700px) { 
    .completeChapterList {
        inset: revert;
        max-width: 300px;
        width: 358px;
        max-height: 468px;
        right: -90%;
    }
}

