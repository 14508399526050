.question-container{
	position: relative;
    /* margin-block-end: 1em; */
}

.question-container__header {

    display: flex;
    justify-content: space-between;
    gap: .5em;

    padding: .5em;
	margin-block-end: 1em;

    width: 100%;
	overflow: hidden;
    background: rgba(47, 153, 215, 0.1);

    border: 4px solid #2f99d7;
    border-radius: var(--border-10);
    color: var(--text-primary-clr);
	transition: max-height 0.5s ease-out;

    background: rgba(47, 153, 215, 0.3);
}

.question-container__header.expand {
    overflow: auto;
    width: 100%;
    height: auto;
	max-height: 200px;
    transition: max-height 0.5s ease-in;
}

.MATHJAXQuestionBody{
    margin-top: 0.3em;
}

.question-container__current-question-container{
    height: auto;
    width: 100%;
    display: flex;
	gap: 1rem;
}

.question-container__current-question{
    top: 20%;
    translate: 0 -50%;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 3px solid var(--accent-primary-clr);
    border-radius: 50%;
    width: 30px;
	min-width: 30px;
    height: 30px;
    background-color: var(--accent-secondary-clr);

    font-weight: 700;
    font-size: 18px;
    padding: 2px;

    transition: all .5s ease;
}

.question-container__current-question-ex {
    position: absolute;

    top: 50%;
    translate: 0 -50%;
    /* bottom: 50%; */

    display: flex;
    align-items: center;
    justify-content: center;

    border: 3px solid var(--accent-primary-clr);
    border-radius: 50%;
    width: 30px;
	min-width: 30px;
    height: 30px;
    background-color: var(--accent-secondary-clr);

    font-weight: 700;
    font-size: 18px;
    padding: 2px;

    transition: all .5s ease;
    z-index: 10;
}

.question-summary-container.big {
    display: none;
}

.question-summary-container.small {
    margin-block-end: 1em;
}  

.question-container__box {
	height: auto;
    overflow: hidden;
    margin-inline: 2.5em;
    width: 100%;
}

.question-container__box.expand {
	overflow: visible;
    height: auto;
	max-height: 800px;
    width: 100%;
    transition: all 1s ease;
}

@media (width > 600px) {
    .question-container__current-question{ 
        width: 36px;
		min-width: 36px;
        height: 36px;
        font-size: 22px;
    }
}

@media (width > 1024px) {
    .question-container {
        /* width: 100%; */
        display: flex;
        justify-content: space-between;
        gap: .5em;
        text-align-last: left;
    } 

    .question-summary-container.big {
        display: block;
        /* margin-block-start: 1em; */
    }

    .question-summary-container.small {
        display: none;
        margin-block-end: 1em;
    }  
}


/* .question-container__fb-exp{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	
	min-width: 1.2rem;
	flex-shrink: 0;
} */

.question-body__expand-btn {
    position: absolute;
    right: 1em;
}

.question-body__feedback-btn {
    position: absolute;
    bottom: 1em;
    right: 1em;
}

/* .question-body__feedback-btn img {
    translate: 0 8px;
} */

.boxReviewQuestionBorder {
    border-radius: 2px rgb(187, 47, 47);
    width: "auto";
    margin-top: 10;
    margin-bottom: 10;
    padding-top: 8;
    padding-bottom: 8;
    padding: 0;
    margin: 0;
}

.questionCheckerContainer {
    display: contents;
    flex-direction: column;
    justify-content: space-between;
    gap: .25em;
}


.boxCheckBsckBox {
    width: 1664px;
    height: 72px;
    background: #100c2a;
    border-radius: 10px;
}

.questionContainerMathjax {
    width: fit-content;
    padding-block: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    line-height: 2vw;

    letter-spacing: 1px;
    max-height: 90%;
    overflow: hidden;
    width: 70%;
    color: #f9f9f9;
}

.circleinQuestion {
    background-color: aqua;
    border-radius: 50%;
    margin: 1em;
}

.MathJAX {
    left: 64px;
}

.questionContainerMathjaxExpanded {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .25em;

    width: fit-content;
    padding: 8px;
    padding-left: 3%;
    padding-top: 1%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    /* font-size: 1.2vw; */
    line-height: 2vw;
    letter-spacing: 1px;
    max-height: 90%;
    min-height: max-content;
    width: 90%;
}

.questionContainerExpand {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .25em;

    box-sizing: border-box;
    height: auto;
    margin-top: 1.5%;
    background: rgba(47, 153, 215, 0.1);
    border: 4px solid #2f99d7;
    border-radius: 10px;
    max-height: fit-content;
    min-height: max-content;
    color: #f9f9f9;
}

mjx-container {
    display: inline !important;
}

.question-image-container {
    flex-shrink: 0;
    margin-left: auto;
}

.question-image {
    margin-block: 1em;
    height: 240px;
    width: auto;
    border-radius: var(--border-10);
}

@media screen and (max-width: 1000px) {

    .questionContainerMathjax,
    .questionContainerMathjaxExpanded {
        font-size: 22px
    }
}

@media screen and (max-width: 600px) {

    .questionContainerExpand {
        display: block;
        margin: 0 auto;
    }

    .circleinQuestionLeftAligned {
        /* position: absolute; */
        width: 15px;
        height: 15px;
        /* left: 5%;
        top: 15%; */

        background: #2f99d7;
    }


    .questionContainerMathjax {
        font-weight: 300;
        /* font-size: 14px; */
        line-height: 20px;
        margin: 0;
        /* padding-top: 3%; */
        top: 0;
        width: 90%;
        left: 10%;
    }

    .questionContainerMathjaxExpanded {
        font-weight: 300;
        /* font-size: 14px; */
        line-height: 12px;
        /* position: relative; */
        margin: 0;
        padding-top: 3%;
        top: 0;
        width: 90%;
        left: 9%;
        margin-bottom: 10%;
        overflow-x: scroll;
    }

    .expandQuestionDetails {
        /* position: relative; */
        right: -5%;
        top: 0%;
    }

    .questionImage {
    height: 160px;
    width: 262px;
}
}
