.tool-tip {
    position: absolute;
    top: 1.2em;

    padding-block: .5em;
    padding-inline: 1.25em;

    min-width: max-content;

    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.75px;

    background-color: var(--bg-primary-clr);
    overflow: hidden;

    border: 4px solid var(--stroke-primary-clr);
    border-radius: var(--border-10);
    z-index: 20;

}

.tool-tip__corner {
    position: absolute;
    width: 15px;
    aspect-ratio: 1/1;
    background-color: var(--stroke-primary-clr);
}

.tool-tip.right-side {
    border-top-left-radius: 0;
    right: revert;
    left: 1.2em
}

.tool-tip.right-side .tool-tip__corner {
    right: revert;
    top: -8px;
    left: -8px;
    rotate: 45deg;
}

/* left-side  */

.tool-tip.left-side {
    border-top-right-radius: 0;
    right: 1.2em;
    left: revert;
}

.tool-tip.left-side .tool-tip__corner {
    left: revert;
    top: -8px;
    right: -8px;
    rotate: 45deg;
}

/* left-side, top-side  */

.tool-tip.top-side.top-side {
    border-radius: var(--border-10);
    border-bottom-right-radius: 0;
    top: -2em;
    right: 1.5em;
    left: revert;
}

.tool-tip.top-side.top-side .tool-tip__corner{
    top: revert;
    right: -8px;
    bottom: -8px;
}

/* left-side, horizontal  */

.tool-tip.left-side.horizontal {
    border-radius: var(--border-10);
    top: -1em;
    right: 2em;
    left: revert;
    overflow: revert;
}

.tool-tip.left-side.horizontal .tool-tip__corner{
    top: 50%;
    translate: 0 -50%;
    right: -.6em;
    z-index: 20;
}

.tool-tip.left-side.horizontal .tool-tip__corner::before {
    content: '';
    width: 12px;
    aspect-ratio: 1/1;
    background-color: var(--bg-primary-clr);
    position: absolute;
    bottom: -1px;
}