.contact-form{
    width: 100%;
    max-width: 730px;
    margin-inline: auto;
    padding: .5em;
    /* margin-inline: .5em; */
    position: relative;
}

.contact-form__title {
    font-size: 38px;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
    line-height: 1.2;
    margin-block-end: .25em;
}

.contact-form__sub-title {
    font-size: var(--font-size-2lg);
    line-height: 1.2;
    color: var( --text-secondary-clr);
    text-align: center;
}

.contact-form__inner-container {
    margin-block-start: 2.5em;
    background-color: var(--bg-secondary-clr);
    padding: 2em;
    border-radius: 6px;
}

.contact-form__item-container {
    display: flex;
    flex-direction: column;
    /* gap: .5em; */
    /* margin-block-end: 2em; */
}

.contact-form__item-container label {
    font-size: 28px;
    font-weight: 600;
}

.contact-form__item {
    display: flex;
    align-items: center;
    gap: .5em;

    margin-block-start: .5em;
    background-color: #191341;
    border: 2px solid #191341; 
    padding-inline: 1em;
}

.contact-form__item.error { 
    background: #c6211b1a;
    border: 2px solid #C6211B; 
    border-radius: 6px;
}

.contact-form__item-container input {
    width: 100%;
    background-color: transparent;
    border-radius: 6px;
    font-size: 18px;
    padding-block: 14px;
    border: none;
}

.contact-form__item-container input:active,
.contact-form__item-container input:focus {
   border: transparent;
   outline: transparent;
}

.contact-form__error-text {
    height: 2em;
    color: #C6211B;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 2; 
    letter-spacing: 0.04688rem;
}

.contact-form-error-icon {
    display: flex;
    align-items: center;
    width: 1.5rem
}

.contact-form__sub-btn  {
   margin-inline: auto;
   min-width: 160px;
}

.contact-form-tick-icon {
    width: 25px;
    padding-block: 2px;
}