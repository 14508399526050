
.dashboard {
    padding-block-start: 6em;
    min-height: calc(100vh - 8em);
}

.dashboard-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-inline: 1em;
    min-height: 83vh;
}

.dashboard-main__info-container {
    width: 100%;
    margin-block-end: 1em;
}

.dashboard-main__stats-container {
    background: #100C2A;
    width: 100%;
    margin-inline: 1em;
    padding: 2em;
    border-radius: var(--border-10);
}

.dashBoardIntelBackBoxAndOtherBoxes {
    padding-block: 1em;
}

@media (width > 600px) {

    .dashboard-main {
        padding: 2em;
        width: 100%;
        max-width: 1300px;
        margin-inline: auto;
    }

    .dashBoardIntelBackBoxAndOtherBoxes {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 1em;
        width: 100%;
        margin-inline: auto;
    }

    .dashboardIntelBackBox {
        flex: 1;
    }
 }

/* ------------------------------------------------------------- question and fluency */

.QuestionsRevisedAndFluencyBox {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 1em;
    color: var(--text-primary-clr);
    text-align: center;

    margin-block-start: 2em;
}


.FluencyRevisedBox,
.QuestionsRevisedBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5em;

    width: 100%;
    max-width: 250px;

    background-color: var(--fg-tertiary-clr);
    border-radius: var(--border-8);
    padding: 2.5em 1em;
    flex: 1;
}
    
@media (width > 600px) {
    .FluencyRevisedBox,
    .QuestionsRevisedBox { 
    width: 100%;
    min-width: 250px;
}
}



.QuestionNumbers,
.FluencyPercentage {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-5lg);
    line-height: 34px;
    color: var(--text-primary-clr);
}

.questionWordsInQuestionNumbers,
.TheFluencyWord {
    font-size: var(--font-size-3lg);
}


@media (min-width: 600px) {

    .QuestionsRevisedAndFluencyBox {
        flex-direction: column;
        justify-content: center;
    }

    .FluencyRevisedBox,
    .QuestionsRevisedBox {
        flex: 0;
    }
}

   /* ------------------------------------------------------------- new questions and start now btn*/

.newQuestionsAndStartNowBtnDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding-block: 1em;

    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;

    /* @media (width > 500px) {
        justify-content: flex-end;
    } */
}

@media (width > 600px) {
    .newQuestionsAndStartNowBtnDiv { 
        justify-content: flex-end;
    }
}

.NewQuestionQuestionStartAndtartChapter {
    flex-shrink: 0;

    display: flex;
    align-items: center;
    text-align: center;
    gap: .5em;

    font-family: "Poppins";
    font-style: normal;
    font-size: 110%;
    line-height: 200%;
    letter-spacing: 0.75px;


    color: #f8219f;
    position: static;
}

.NewQuestionQuestionStartAndtartChapter .bold.text {
    font-weight: 700;
}

.newChapterStartNowNavToNewLinks {
    padding: .5em 1.5em;
    font-size: var(--font-size-sm);
    border: 2px solid #f8219f;
    border-radius: var(--border-8);
    color: var(--accent-primary-clr);
    flex-shrink: 0;
}

.StartThisChapterFromDefualt,
.TodayRevisionSpeedUpTheContext {
    display: flex;
    align-items: center;

    padding: .7em 1.2em;
    margin-block-start: 1em;
    padding: .5em 1em;
    background: linear-gradient(270deg, #f8219f 22.92%, #7b28a9 100%);
    border-radius: var(--border-8);
    color: var(--text-primary-clr);
    font-weight: 600;
    font-family: "Poppins";
}

.StartThisChapterFromDefualt:active,
.TodayRevisionSpeedUpTheContext:active {
    scale: .98;
}

.TodayRevisionSpeedUpTheContext {
    margin-inline: auto;

    /* @media (width < 500px) {
    margin-inline: revert;
    } */
}


.startNowButtonToNewChapter {
    color: var(--accent-primary-clr);
}


@keyframes runAway {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(200%);
        display: none;
    }
}


.chapterTitleForNewDashboard {
    padding-left: 1rem;
}

.ExtraBoilarPlateDetails {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-2lg);
    line-height: 1.5em;
    letter-spacing: 1px;
    margin-bottom: .5em;

    color: var(--text-primary-clr);
}

.DefaultStateDiv {
    
    /* position: absolute;
    top: 42.5%;
    width: 35vw;
    left: 20vw; */

	padding-inline: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
	flex: 2 1;
    z-index: 2;

    @media (width < 600px) {
        display: none
    }
}


@media screen and (max-width: 1100px) {
    .chapterQuestionAndTime > p {
        display: flex;
        width: 15vw;
    }
    .chapterQuestionAndTime {
        font-size: 1.3vw;
    }

}

@media screen and (max-width: 600px) {

    /* .dropdownBtnIcon {
        width: 70%;
        padding-left: 5%;
        margin-left: 4px;
    } */

    .chapterQuestionAndTime {
        display: flex;
        width: max-content;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        /* identical to box height, or 100% */

        letter-spacing: 0.75px;
        position: static;

        margin: 3%;
        margin-left: 7.5%;
        padding: 0;
    }


    .chapterQuestionAndTime > p {
        width: 8rem;
        padding: 0;
    }


    .QuestionsMinLeftwithinMins {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 80%;
        line-height: 300%;
        /* identical to box height, or 317% */

        letter-spacing: 0.75px;

        color: #f8219f;
        width: 100%;
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .FluencyPercentage {
        position: static;
        left: 15%;
        top: 30%;
    }


    .TheFluencyWord {
        position: static;
        width: auto;
    }
    .startNowButtonToNewChapter {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 100%;
        line-height: 150%;
        /* identical to box height, or 200% */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.75px;

        color: #f8219f;
        position: static;
    }

    /* --------------------------------------------------- */

    .dashboard {
        overflow-x: hidden;
    }


}


@keyframes fade-up {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.text-new-question {
    width: max-content;
}