.userFooterLoggedInDashboard {

    margin-top: auto;

    width: 100%;
    background: var(--bg-secondary-clr);
    color: var(--text-primary-clr);
    padding-block: 1em;
}

.footerNavLinkDivUserProfile{
	cursor: pointer;
}

.footerNavLinkDivUserProfile_navlinkDrops {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;
    padding-inline: 1em;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    flex-shrink: 0;
    line-height: 34px;
    letter-spacing: 0.75px;
    color: var(--text-primary-clr);
}

@media screen and (max-width: 900px) {
    .userFooterLoggedInDashboard {
        position: relative;
    }

    .footerNavLinkDivUserProfile_navlinkDrops {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 18px;
    }

    .footerNavLinkDivUserProfile {
        padding: 2.5%;
    }
}

@media screen and (max-width: 390px) {
    .userFooterLoggedInDashboard {
        position: absolute;
        top: 95%;
        height: 45%;
    }
}
