
.article-card {
    display: flex;
    flex-direction: column;
    background: var(--bg-secondary-clr);
    padding: 1.5em;
}

.article-card__img-container {
  overflow: hidden;
  border-radius: var(--border-6);
  width: 100%;
}

.article-card__img {
    border-radius: var(--border-6);
    /* aspect-ratio: 1.5/1; */
    object-fit: cover;
    height: 340px;
    width: 100%;
    transition: scale .5s ease;
}

.article-card__img:hover {
    scale: 1.15;
}

.article-card__title {
    margin-block: .7em;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.5px;
    font-weight: 700;
}

@media (width < 900px) {
    .article-card__title {
        font-size: 22px;
        line-height: 1.5;
    }
}

.article-card__sub-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.25px;
    color: var(--text-secondary-clr);
}

.article-card__body {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .5em;
}

.article-card__button-info {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
}

.article-card__seperator-dot {
    display: inline-block;
    margin-inline: 10px;
    width: 12px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--accent-primary-clr);
}