.chapterDisplayDivForProfile {
	position: relative;
	background: #100c2a;
    border-radius: 10px;
    min-width: 170px;
    max-width: 300px;
    flex-basis: 210px;
    height: 210px;

	flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0.5vw;
	gap: 1em;

	font-family: "Poppins";
    font-style: normal;

	@media (width < 600px){
		max-width: 600px;
		flex-direction: row;
		justify-content: start;
		margin: 1em 0;
		padding-inline: 1em;
		max-height: 110px;
	}
}

.chapterDisplayImg {
    width: 50%;
    height: 50%;

	@media (width < 600px){
		width: 72px;
        height: 72px;
    }
}

.chapterDisplayName {
    font-weight: 700;
    font-size: var(--font-size-sm);
    letter-spacing: 1px;
	
    padding: 0 1rem;
	max-height: 3em;
	
    text-align: center;
    color: #ebebec;
    overflow: hidden;
	
	@media (width < 600px){
        font-size: 14px;
        line-height: 14px;
        text-align: left;
    }
}

.chapterSelectBtnContainerForProfile {
	position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.8);
}

.chapterDisplayDivForProfile:hover .chapterSelectBtnContainerForProfile {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	
	@media (width < 600px){
		left: 0;
		flex-direction: row;
		justify-content: center;
    }
}

.chapterSelectBtnForProfile {
	padding: 0.8vw 1.8vw;
    margin: 0.25rem;
    width: 70%;
    margin-top: 1rem;
    border-radius: 10px;

	@media (width < 600px){
		padding: 2.5%;
        font-size: 10px;
        margin: 2%;
    }
}