.dropDownChapters{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdownBtnIcon{
    flex-shrink: 0;
    max-width: 50px;
    width: 100%;
}

.chapter-filter__chapter-name-containter {
    width: 100%;
    max-width: 360px;
    padding-inline: .5em;
}

@media (width > 600px) {
    .chapter-filter__chapter-name-containter {
        max-width: 400px;
    }
}

.chapter-filter__chapter-name {
    width: 100%;
    margin-inline: .5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .chapter-filter__chapter-name.anim {
    animation: slide 2s ease-in infinite;
}

@keyframes slide {
    to {
        translate: calc(-66.6666%) 0;
    }
} */

.chapter-title {
    display: flex;
    align-items: center;
    gap: .25em;
    margin-block: 1em;
    width: max-content;
    font-weight: 700;
    font-size: var(--font-size-3lg);
    letter-spacing: 1px;
    color: var(--text-primary-clr);

    position: relative;
}

.chapter-title__dropdown-chapter-list {
    position: absolute;
    top: 2em;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: left;

    background-color: var(--fg-primary-clr);
    padding: 0.5rem;
    height: 468px;
    width: 320px;
    max-width: 500px; 
    
    border: 0.2rem solid #2f99d7;
    border-radius: 0.5rem;
    /* overflow-y: auto; */
    color: var(--text-primary-clr);

    z-index: 20;
}

.dropdown-chapter-list__chapter-title {
    font-size: var(--font-size-3lg);
    font-family: "Poppins";
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0.75px;
    color: var(--text-primary-clr);
    padding: 1rem;
}

.dropdown-chapter-list__container {
    padding-inline-end: 1em;
    overflow-y: auto;
}


@media (max-width: 700px) {
    .chapter-title__dropdown-chapter-list {
        /* position: fixed; */
		left: 50%;
		transform: translate(-50%, 0);
    }
}