
.mainChapterSelectionContainer {
  margin-block-start: 5em;
  padding: 1em;
  background-color: var(--bg-primary-clr);
}

.allChapterTitle {
  font-size: var(--font-size-3l);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 1px;
  color: var(--text-primary-clr);
  transition: all .5s ease;
}

.chapterHeader {
  position: sticky;
  top: 4.5rem;
  z-index: 10;
  background: var(--bg-primary-clr);

  display: flex;
  align-items: center;
  gap: 0.7em;

  padding-block: 1em;
  padding-inline: 1em;
}

.chapterListContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

@media (min-width: 1024px) {

  .mainChapterSelectionContainer {
    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;
  }

}


/* chapter styles*/

.chapterContainer {
  position: relative;

  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 5%;

  background-color: var(--bg-secondary-clr);
  padding-block: 1em;
  padding-inline: 1em;
  border-radius: var(--border-8);
  transition: all .5s ease;
}

.mainChapterTitleAndImg {
  flex-shrink: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}

.TimerAndQuesNoDiv {
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.chapterHeadingForLargeScreen,
.chapterHeadingForSmallScreen {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-md);
  letter-spacing: 1px;
  color: var(--text-primary-clr);
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.chapterHeadingForLargeScreen {
 display: none;
}

.chapterHeadingForLargeScreen.selected,
.chapterHeadingForSmallScreen.selected {
  color: var(--accent-primary-clr);
}

.chapterImg {
  width: 100px;
  flex-shrink: 0;
}

.timerAndQuesNo {
  display: flex;
  gap: .7em;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-sm);
  line-height: 34px;
  color: var(--text-primary-clr);

  min-width: 200px;
}

.chapterSelectBtnContainer {
  position: absolute;
  background-color: #000000c0;
  inset: 0;

  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 1em;
  flex-direction: column;

  animation: fadeIn .18s ease;
}

.chapterSelectBtn {
  padding: 0.87em 2em;
  border-radius: var(--border-10);
  font-size: var(--font-size-sm);
  margin-inline: auto;
  width: 200px;
}

.selectThisChapterBtn {
  background: linear-gradient(270deg, #f8219f 0%, #7b28a9 100%);
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.viewInfromationBtn {
  color: #f82191;
  border: 2px solid #f82191;
}

@media (min-width: 700px) {

  .allChapterTitle {
    font-size: var(--font-size-40);

  }
  .chapterImg {
    width: 150px;
  }

  .chapterHeadingForOnClickMobile {
    display: none;
  }

  .chapterSelectBtnContainer {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .chapterSelectBtn {
    width: max-content;
    margin-inline: revert
  }
}

@media (min-width: 930px) {
  .chapterContainer {
    justify-content: space-between;
    padding-inline: 2em;
  }
  .chapterHeadingForLargeScreen {
    font-size: var(--font-size-3lg);
    display: block;
  }
  .chapterHeadingForSmallScreen  {
    display: none;
  }
}

@media (max-width: 600px) {
  .chapterHeader {
    justify-content: space-between;
    padding-inline: 1.5em;
  }
}

@media screen and (max-width: 700px) {
  /* .mainChapterSelectionContainer {
    overflow: hidden;
  } */

  .allChapterTitle {
    position: relative;

    font-size: 22px;
    line-height: 34px;
    letter-spacing: 1px;
    color: #f9f9f9;
  }

}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* chapter-practice styles */

.topWrap{
	position: sticky;
	top: 5em;
	min-height: fit-content;
	z-index: 2;
	background: var(--bg-primary-clr);
}

.chapter-practice {
  position: relative;
  margin-block-start: 5em;
  /* padding-block-start: 1em; */
  padding-inline: 1em;
}

@media (min-width: 1024px) {
	
   .chapter-practice {
    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;
  }

}
