
.about__blog-carousel {

	--swiper-height: 0;
	/* position: relative; */
	width: 100%;
	max-width: 1500px;
	margin-inline: auto;

}

.blog-carousel__title {
	font-size: 2rem;
    font-weight: 700;
	padding-bottom: 1rem;
	text-align: center;
}

.about-us__swiper-container {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.about-us__swiper{
	width: 90%;
	margin-inline: auto;
	overflow: auto;
}

.blog-carousel__card {
	display: flex;
	flex-direction: column;
	align-items: center;
 	gap: 1em;

	padding-block: 4em;
	padding-inline: 1em;

	border: 3px solid var(--stroke-primary-clr);
	border-radius: 10px;
	text-align: center;
}

.blog-carousel__card-img {
	/* width: 40%; */
	height: 100%;
	width: 50%;
	border-radius: 8px;
}

.blog-carousel__card-title {
	font-size: 25px;
	line-height: 1.2;
	font-weight: 700;
	color: var(--text-primary-clr);
}

.blog-carousel__card-sub-title {
	font-size: 20px;
	color: var(--text-secondary-clr);
	line-height: 1.2;
}

.blog-carousel__card-date {
   color: var(--stroke-primary-clr);
}

.blog-swipe-btn {
	width: max-content;
	position: absolute;
	top: 43%;
	translate: 0 -50%;
	display: none;
}

.blog-swipe-btn.-left{
	left: 4px;
}

.blog-swipe-btn.-right{
	right: 4px;
}

.blog-swipe-btn:active {
	scale: .95;
}

.blog-swipe-btn-icon.flip-x {
	rotate: 180deg;
}

.about-us__swiper  .swiper-slide {
	margin-bottom: 3em;
 }

 @media (width > 800px) {
	.blog-swipe-btn {
		display: block;
	}
 }

@media (width > 1000px) {
	.blog-carousel__card {
		flex-direction: row;
		text-align: left;
		padding: 2em;
	}

	.about-us__swiper  .swiper-slide { 
		height: 100%;
    }

}