.about{
    padding-block-start: 8em;

    background-color: var(--bg-primary-cl);
    font-family: 'Poppins';
    font-style: normal;
    color: var(--text-primary-clr);
}

.about__title{
    font-weight: 700;
    font-size: 2rem;
    line-height: 50px;
    letter-spacing: 1px;
    text-align: center;
    padding-block-end: 1em;
}

.about__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;
    margin-block-end: 4em;
}

.about__container-small {
    max-width: calc(var(--app-width) - 5%);
    margin-inline: auto;
}

.about__inner-container {
    padding-inline: 1em;
    width: 100%;
    max-width: 620px;
 }

.about-hero-paragraph {
    width: 100%;
    max-width: 500px;
 }

.about-hero-img {
    width: 100%;
    max-width: 650px;
}

.about-img-main {
    background: transparent;
    text-align: center;
}

.about__sub-text {
    margin-block-end: 1em;
    font-size: 20px;
    color: #B9B7BF;
}

.blog-img-2, 
.blog-img-3, 
.blog-img-4{
    width: 100%;
    max-width: 600px;
}

.about__container-vision,
.about__container-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.about-vision__title,
.about__team__title {
    font-size: 2rem;
    font-weight: 700;
	padding-bottom: 1rem;
} 

.about__inner-container-team {
    width: 100%;
    margin-block: 2em;
}

.team-leader {
    justify-content: center;
    margin-inline: auto;
}

.about__container-team-members {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.about__team-member {
    width: 240px;
    margin: 10px auto;
}

.about__team-member-img {
    background-image: linear-gradient(90deg, #5f4cc9ae 10%,  #f8219eae 100%);
    border-radius: 50%;
    overflow: hidden;
}

.about__team-member-name {
    margin-block-start: .5em;
    font-size: 18px;
    font-weight: 700;
    color: var(--accent-primary-clr);
}


@media (width > 700px) {
    .about__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: clamp(2em, 3vw, 10%);
        min-height: 60vh;
    }

    .about__container.reverse {
        flex-direction: row-reverse;
     }
}