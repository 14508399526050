.header {
    position: fixed;
    top: 0px;
  
    display: flex;
    justify-content: center;
    width: 100%;
	/* height: 4rem; */

	font-family: 'Poppins';

    padding-block: 1em;
    background: #100c2a;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    /* background-color: green; */
    z-index: 10;
  }

  /* simple navlinks */
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin-inline: 1em;
  }
  
  .header__left,
  .header__right {
    width: 100%;
    max-width: 42%;
  }
  
  .header__right {
    justify-content: end;
  }
  
  .header__site-logo {
    display: flex;
    align-items: baseline;
    gap: 2px;
    color: var(--text-primary-clr);
  }

  .siteIcon {
    width: 40px;
  }
  
  .header__site-name {
	font-weight: bold;
	letter-spacing: 1px;
	color: var(--accent-primary-clr);
    font-size: var(--font-size-22);
    display: none;
  }
  
  .navbar__main {
    display: flex;
    align-items: center;
    gap: 1em;
  
    color: white;
    font-weight: 600;
  }
  
  .navbar_link {
    font-size: var(--font-size-md);
    min-width: max-content;
  }
  
  .navbar__practice_link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
    min-width: max-content;
  
    padding: 0.5rem 2rem;
    background: linear-gradient(270deg, #f8219f 0%, #7b28a9 100%);
  
    border-radius: 10px;
  }

  .navbar__sign-in_link {
    text-align: center;
    border: 2px solid var(--accent-primary-clr);
    font-size: var(--font-size-md);
    color: var(--accent-primary-clr);
    padding: .5em 1em;
    border-radius: var(--border-8);
    width: max-content;

    display: none;
  }
  
  .navlinksdivMainLandpic {
    position: relative;
    top: 1rem;
  }
  
  .UserHeader {
    z-index: 5;
  }
  
  .header__right {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  
  .header__menu-btn {
    flex-shrink: 0;
  }
  
  .navbar__slider {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
  
    display: flex;
    gap: 32px;
    padding-inline-end: 1.25em;
  
    width: 100%;
    max-width: 350px;
    /* height: 100vh; */
    padding: 1.5em 1.5em;
  
    font-size: 20px;
    font-weight: 600;
    color: #f9f9f9;
    background-color: #100c2a;
    z-index: 20;
  
    translate: 100% 0;
    transition: all 0.15s ease;
  }
  
  .navbar__slider.show {
    translate: 0% 0;

    @media (width > 850px) {
      display: none
    }
  }


  
  .navbar__slider__close {
    display: block;
    margin-block-end: 4em;
  }
  
  .navbar__slider__menu-item {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-block-end: 0.5em;
  }
  
  @media (min-width: 768px) {
    .header__site-name {
      display: block;
    }
  }

  @media (min-width: 1024px) {
    .header-container {
      margin-inline: 2em;
    }
  }
  
  @media (max-width: 600px) {
    .sidebarForHeader {
      position: static;
    }
  }
  
  @media (max-width: 850px) {
    .navbar_link {
      display: none;
    }
  }
  
  @media (min-width: 850px) {
    .header__menu-btn {
      display: none;
    }
    .navbar__sign-in_link {
      display: block;
    }
  }
  
  /* AuthenticatedNavLinks styles */

  .navbar__slider__menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

.profile__pic-container {
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    border: 2px solid var( --accent-primary-clr);
    height: 32px;
    width: 32px;

    @media (width < 850px) {
      display: none
  }

}
  .profile__pic {
    color:  var( --accent-primary-clr);
    translate: -.5px 0;
  }

  .navbar__slider__user-info-container {
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1em;
    margin-block-end: 1em;
  }

  .navbar__slider__user-info-container .profile__pic-container {
    height: 70px;
    width: 70px;
  } 

  .navbar__slider__user-info-container .profile__pic {
    font-size: var(--font-size-5lg);
  }

  .navbar__slider__user-info-container .display-name {
    font-size: var(--font-size-3lg);
  }

  .navbar__slider__btn-container {
    display: flex;
    justify-content: center;
  }

  .navbar__slider__menu-item.log-out-btn {
    text-align: center;
    border: 2px solid var(--accent-primary-clr);
    font-size: var(--font-size-md);
    color: var(--accent-primary-clr);
    padding: .5em 1em;
    border-radius: var(--border-8);
  }