/* ::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: var();
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

.chapterTitle {
    margin: 1rem;
    margin-top: 0;
    display: flex;

    position: relative;

    left: 1%;
    margin-top: 1.2%;

    /* H2_Bold */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.7vw;
    line-height: 50px;
    /* identical to box height, or 104% */

    letter-spacing: 1px;

    color: #f9f9f9;
    align-items: center;
}

@media (width > 600px) {
    .chapter-practice-header {
        display: flex;
        align-items: center;
        gap: 1em;
    }
}

.stepsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.displayMainContent {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: 400;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none; 
    margin: 0 auto;
    background-color: #08091c;
}

.displayStepSubContainer {
    overflow: auto;
    scrollbar-width: none; 
    width: 100%;
    max-height: 55vh;
    /* margin-inline: 1.5em; */
}


.displayMainContent::-webkit-scrollbar, 
.displayStepSubContainer::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }


.mainTitleForReviewMode {
    display: flex;
    color: white;
    align-items: baseline;
}

.chapterList {
    position: absolute;
    z-index: 2;
    top: 3rem;
    left: 90%;
    /* width: 500px; */
    background-color: #19122c;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: left;
    border: 0.2rem solid #2f99d7;
    border-radius: 0.5rem;
    height: 16rem;
    overflow-y: auto;
    overflow-x: hidden;
    animation: fadeIn ease 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.chapterInTheList {
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    /* identical to box height, or 189% */

    letter-spacing: 0.75px;

    color: #f9f9f9;
}

.revisionTitle {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    /* identical to box height, or 155% */

    letter-spacing: 0.75px;

    color: #f9f9f9;
    padding: 1rem;
}

.newQuestionBtn-container {
    display: flex ;
    justify-content: center;
    padding-block: 1em;
    cursor: pointer;
}

.newQuestionBtn {
    display: flex;

    border: 0.2rem solid #eb21a0;
    background-color: transparent;
    text-transform: unset;
    color: #eb21a0;
    font-size: 12;
    font-family: "Poppins";

    padding: 0.5rem 1rem;
    border-radius: 0.5rem;

    width: max-content;
}

.chapterListForReviewMode {
    position: absolute;
    z-index: 2;
    top: 12%;
    left: 25%;
    /* width: 15%; */
    background-color: #19122c;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: left;
    border: 0.2rem solid #2f99d7;
    border-radius: 0.5rem;
    height: 16rem;
    overflow-y: auto;
    color: white;
}

.newQuestionBtnChapterReview {
    position: absolute;
    bottom: 5%;

    display: flex;
    border: 0.2rem solid #eb21a0;
    /* justify-content: center; */
    background-color: transparent;
    text-transform: unset;
    color: #eb21a0;
    font-size: 12;
    font-family: "Poppins";
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    

}

/* chapter list for userlanding page */
.chapterDisplayInList {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem 0;
}

.chapterTitleAndProgInList {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}

.chapterProgInList {
    margin-left: 2rem;
    width: 7rem;

    border: 2px solid #f8219f;

    /* border-image-source: linear-gradient(270deg, #f8219f 0%, #7b28a9 100%); */

    background-color: transparent;
    color: transparent;
    border-radius: 4px;
}

.chapterProgInList::-webkit-progress-value {
    background: linear-gradient(270deg, #f8219f 0%, #7b28a9 32.98%);
    border-radius: 2px 0px 0px 2px;
}

.chapterProgInList::-moz-progress-bar {
    background: linear-gradient(270deg, #f8219f 0%, #7b28a9 32.98%);
    border-radius: 2px 0px 0px 2 px;
}

.chapterTimeInList {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 0.75px;

    color: #b9b7bf;
}

.chapterImageInList {
    width: 4rem;
    height: 4rem;
}

@media screen and (max-width: 1000px) {
    .chapterTitle {
        font-size: 2.5vw;
    }
    /* .newQuestionBtn {
        left: 42.5%;
    } */
}

@media screen and (max-width: 600px) {
    .chapterTitle {
        position: relative;
        left: -1%;
        margin-top: 2.5%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 34px;
        /* identical to box height, or 170% */

        letter-spacing: 1px;

        color: #f9f9f9;
    }
    .displayMainContent {
        position: relative;
        padding-bottom: 10;
        width: 100%;
        height: 500;
        overflow: hidden;
        overflow-y: auto;
        margin: 0 auto;
        background-color: #08091c;
        left: 0;
        box-shadow: inset 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
    }


    /* .newQuestionBtn-container {
        justify-content: center;

    } */

    .chapterListForReviewMode {
        width: 80%;
        left: 10%;
        top: 15%;
        display: flex;
        flex-direction: column;
    }
    .chapterInTheList {
        overflow: visible;
    }

    .feedbackAndshowStepContainerForMobile {
        background: #100c2a;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 3%;
        width: 100vw;
        position: fixed;
        left: 0;
        bottom: 0;
    }
    .showStepBtnForReviewModeAndMobile {
        bottom: 0;
        font-size: 12px;
        line-height: 24px;
        font-style: normal;
        font-weight: 600;

        border: 2px solid #f8219f;
        border-radius: 6px;
    }
    .stepsFeedbackBtnContainerForMobile {
        margin: 0.5rem 2rem;
    }
    .feedbackBtn {
        /* height: 42px; */
    }

    .feedbackBtnForMobile {
        width: 20vw;
        font-weight: 500;
        font-size: 10px;
        line-height: 38px;
        /* identical to box height, or 380% */

        letter-spacing: 1px;

        color: #f9f9f9;
    }
}
