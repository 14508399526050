.MainProfileContainerWithBackGround {
    margin-block-start: 6em;
    padding: 1em;
    font-family: 'poppins';
    font-style: normal;
    color: #f9f9f9;

	@media (width > 500px) {
		width: 100%;
		max-width: var(--app-width);
		margin-inline: auto;
	}
}

@media (width > 600px) {
    .MainProfileContainerWithBackGround { 
        width: 100%;
		max-width: var(--app-width);
		margin-inline: auto;
    }
}

.MainProfileContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-end: 1em;
}

.myProfileTitleForProfile {
    font-weight: 700;
    /* line-height: 34px; */
    letter-spacing: 1px;
    font-size: var(--font-size-5lg);
    color: var(--text-primary-clr);
}

.logOutButtonForProfile {
	font-size: small;
	padding: 0.7em 2em;
	background: #100C2A;
	color: #f8219f;
	
	border: 2px solid #f8219f;
	border-radius: var(--border-8);
	
	@media (min-width: 500px) {
		display: block;
	}
}

.personalInfoAndCreditContainer{
    display: flex;
    flex-direction: column;
    gap: 1em;
	padding-block: 0.2em;

	@media (width > 786px){
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
}

.personalInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;

	min-width: fit-content;
	max-height: fit-content;

    padding: 2em;
    border-radius: var(--border-8);
    background-color: var(--fg-primary-clr);

	transition: transform 1s ease-in;

	@media (width < 1024px ) {
		flex-direction: column;
		justify-content: center;
	}

	@media (width < 786px ) {
		flex-direction: row;
	}

}

.imageForProfile {
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 52px;
    height: 52px;

    border: 3px solid #f8219f;
    border-radius: 50%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-48);
    color: var( --accent-secondary-clr);

	@media (width > 500px){ 
        width: 112px;
        height: 112px;
    }
}





.fullNameTitle{
    display: none;
    font-size: var(--font-size-md);
}


.totalCreditContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5em 2em;

    background-color: var(--fg-primary-clr);
    border-radius: var(--border-8);

	@media (width> 600px){
		flex-direction: row;
        gap: 2em;
    }
}

.totalCreditsTitleContainer {
	display: flex;
    justify-content: center;
    align-items: center;
    gap: .5em;
	
    font-weight: 700;

	@media (width> 600px){
        flex-direction: column;
        flex-shrink: 0;
    } 
}

.creditDisplay {
    font-size: var(--font-size-36);
    color: var(--accent-primary-clr);

	@media (width > 600px){
        font-size: 64px;
        line-height: 66px;
    }
}

.totalCreditsDisplay {
    flex-shrink: 0;

    line-height: 33px;
    letter-spacing: 1px;
    color: var(--text-primary-clr);
	
	@media (width> 786px){
		font-size: var(--font-size-2lg);
		text-align: center;
	}
}

.referalContainerForCredit {
	display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
	gap: 0.2em;
	
	margin-block-end: 0.7em;

	@media (width> 600px){
		text-align: left;
    }
}

.startReferingNowTitle {
	font-weight: 700;
    letter-spacing: 1px;
    font-size: var( --font-size-lg);
    color: var(--text-primary-clr);
	
	@media (width> 786px){
		font-size: var(--font-size-lg);
        line-height: 30px;
    }
}

.referalInformation {
    font-weight: 400;
    font-size: var(--font-size-sm);
	
    text-align: center;
    color: var( --text-secondary-clr);
	
	@media (width> 600px){
        text-align: left;
    }
}

.copyLinkForReferalBtn {
	display: flex;
	align-items: center;
	gap: 0.5em;
    font-weight: 500;
    font-size: var(--font-size-sm);
    color: var(--accent-primary-clr);

    padding: .7em 1.2em;
    border: 2px solid var(--accent-primary-clr);
    border-radius: var(--border-8);
}

.sideLineForProfile {
    width: 87.5vw;
    height: 0.5vh;
    left: 6.67%;
    right: 93.12%;
    top: 56.25vh;
    background: #19122c;

	@media(width < 600px){
		width: 88%;
		top: 71.5%;
		height: 0.5vh;
	}
}

.PurchaseAndAllChapterDiv {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-block: 1em;
	/* min-height: max-content; */

	background-color: var(--bg-primary-clr);
	position: sticky;
	top: 4.5em;
	z-index: 3;

    letter-spacing: 1px;
	font-weight: 400;
}

.purchaseAndAllChapterSubDiv{
	min-height: 2.5rem;
}

/* .fillerDiv{
	flex: 1;
	height: 2.5em;
	border-bottom: 0.35vh solid var(--accent-primary-clr);
} */

.allChapterTitleForProfile {
	font-size: var(--font-size-16);
}	

.inActiveChapterTitleForProfile {
    transition: color 0.3s ease, font-size 0.3s ease;
}

.activeChapterTitleForProfile {
	font-size: var(--font-size-18);	
	font-weight: 700;

	box-sizing: border-box;
	color: var(--accent-primary-clr);
	border-bottom: 0.35vh solid var(--accent-primary-clr);

	transition: color 0.3s ease, border-bottom 0.3s ease, font-size 0.3s ease;

	@media ( width > 500px){
        line-height: 2em;
    }
}

.chapterDisplayContainerForProfile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    /* max-height: 38vh; */
	flex: 1 1 0px;
	width: 100%;
	gap: 1em;
	
	@media (width < 600px){
		max-height: fit-content;
		display: block;
        /* flex-direction: column;
        justify-content: flex-start;
		flex-wrap: nowrap; */
    }
}


.totalCreditContainer__inner-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 600px) { 
		text-align: left;
		flex-shrink: 1;
		align-items: start;
	} 
}

.purchaseEmpty{
	height: 280px;
	display: flex;
	flex-direction: column;
	justify-content: center ;
}

.purchaseEmpty__title{
	font-weight: 700;
	font-size: var(--font-size-3lg);
}

.purchaseEmpty__btn{
	margin-block-start: 1.2em;
}

