
.contact-us {
    margin-block-start: 8em;
    margin-block-end: 6em;
    color: var(--text-primary-clr);
    width: 98%;
    max-width: 730px;
    margin-inline: auto;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (width > 700px) {
    .contact-us {
        margin-block-start: 6em;
    }
}

.contact-us-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-block-end: 1em;
}

.contact-us__title {
    margin-block-end: 1em;
    text-align: center;
    font-size: clamp(var(--font-size-3lg), 5vw, var(--font-size-4lg));
    font-size: var(--font-size-5lg);
    font-weight: 700;
    line-height: 1.2;
}

@media (width < 700px) {
    .contact-us__title{
        width: 300px;
        margin-inline: auto;
    }
}

.contact-us__form {
    display: flex;
    flex-direction: column;
    /* gap: 1em; */
    padding: 2em;
    background-color: var(--fg-secondary-clr);
    border-radius: 8px;
}

.contact-us__form__label {
    /* font-size: clamp(var(--font-size-2lg), 5vw, var(--font-size-3lg)); */
    font-size: clamp(var(--font-size-3lg));
    font-weight: 700;
}

.contact-us__form__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: .25em; */
    font-size: var(--font-size-lg);
}

.contact-us__form__input {
    display: flex;
    align-items: center;
    padding-inline: 1em;
    margin-block-start: .25em;

    background-color: var(--bg-secondary-clr);
    border: 2px solid var(--bg-secondary-clr);;
    border-radius: 8px;
}

.contact-us__form__input.error { 
    background: #c6211b1a;
    border: 2px solid #C6211B;
}

.contact-us__form__item input,
.contact-us__form__item textarea{
    background-color: transparent;
    font-size: clamp(var(--font-size-sm), 5vw, var(--font-size-lg));
    padding-block: 14px;
    border-radius: 8px;
    width: 100%;
}

.contact-us__form__item input:focus,
.contact-us__form__item textarea:focus{
    outline: transparent;
}

.contact-us__form__item textarea{ 
    min-height: 100px;
    resize: none;
}

.contact-us__form__error-text {
    height: 1.45em;
    color: #C6211B;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.5; 
    letter-spacing: 0.04688rem;
}

.contact-us__footer {
    position: fixed;
    inset: 0;
    top: auto;
    margin-left: auto;

    padding-block: 1.5em;
    background-color: var(--bg-secondary-clr);
}

@media (width < 500px) {
    .contact-us__footer {
        position: static;
    }
}

.contact-us__footer-container {
    width: 100%;
    max-width: var(--app-width);
    margin-inline: auto;
}

.contact-us__footer .ceo-info {
    text-align: center;
    margin-block-end: 1em;
}

.contact-us__footer .contact-info{
   display: flex;
   justify-content: space-evenly;
   flex-wrap: wrap;
   gap: 1em;
}

@media (width < 800px) {
    .contact-us__footer .contact-info { 
        justify-content: flex-start;
    }
 }

.contact-info a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5em;
    padding-inline: 1em;
    /* color: var(--text-secondary-clr); */
    color: var(--text-primary-clr);
    font-weight: 500;
 }

 .contact-info button h3 {
    color: inherit;
 }

.contact-us__icon {
    width: 20px;
    aspect-ratio: 1/1;
}

.contact-us__sub-btn {
    margin-block-start: 1em;
    margin-inline: auto;
    min-width: 160px;
}

.contact-us-tick-icon {
    width: 25px;
    padding-block: 2px;
}