.coverage-card {
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5em;

    padding: 1em;

    background: rgba(75, 186, 123, 0.05);
    border: 2px solid #4BBA7B;
    border-radius: 10px;

    max-width: 50%;
}

.coverage-card__coverage-name {
    /* flex-shrink: 0; */
    flex: 1;
    width: max-content;
}

.coverage-card__tick-icon {
    width: 20px;
    aspect-ratio: 1/1;
}