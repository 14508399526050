
.display-step-container {

    /* display: flex; */
    align-items: flex-start;
    
    /* margin-inline: 1em; */
    padding-block: 1em;

    --border-size: 3px;
    --border-angle: 0turn;
	
	font-family: "Poppins";
	font-style: normal;
    background: #08091C;
}

.display-step.curr-step-border {
    background: #f8219f1a;
}

.display-step-no {
    background-color: #130e23;
    border-radius: 0.5rem;

    display: flex;
    align-items: center;
    /* width: 7em; */
    /* min-width: 6em; */
    height: 40px;
    justify-content: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 38px;
    letter-spacing: 1px;
    color: var(--text-primary-clr);
}

.display-step {
    position: relative;
    display: flex;
    justify-content: space-between;
    
    background-color: var(--bg-primary-clr);
    padding-block: 2em;
    padding-inline: 1em;
    min-height: 75px;

    font-size: 1em;
    letter-spacing: 1px;
    border-radius: 0.5rem;
    margin-inline: 1em;

    color: var(--text-primary-clr);
    background: #0C172F;
}

.display-step__step-no {
    position: absolute;
    top: -1em;

    flex-shrink: 0;
    width: max-content;
    padding-inline: 5px;

    background: linear-gradient(var(--bg-primary-clr) 85%, #0c172f00 40%);
    border-radius: 8px;

    color: var(--stroke-primary-clr);
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    letter-spacing: 1px;
    z-index: 1;
}

.display-step__step-no.current-step {
    color: var(--accent-primary-clr);
}

.stepAnswerContainer__result {
    flex: 1;
    /* width: 100%; */
    /* width: 100%;
    display: flex;
    flex-direction: column; */
    gap: 1em;
}

.app-flex-13 {
    width: 100%;
    background-color: green;
}

.stepAnswerContainer__result.current-view {
   margin-inline-end: 2.5em;
}

.stepAnswerContainer__img {
    margin-block-start: 1em;
    width: "100%";
    border-radius: var(--border-10);
    height: 240px;
}

@media (width > 1024px) {
    .stepAnswerContainer__result {
        display: flex;
        flex-direction: row;
        gap: 5%;
    }

    .stepAnswerContainer__result-media {
        flex-shrink: 0;
        margin-left: auto
    }

    .stepAnswerContainer__img {
        margin-block-start: revert;
    }
    
     
}

/* explanation box */
/* //////////////////////////////////////////////////////////////////////////////////// */

.explanationModal {
    display: hidden;
}

.explanationunderscorebar {
    position: absolute;
    /* right: 68.65%; */
    left: 12.5%;
    top: 18.5%;
    /* bottom: -7.41%; */
    background: linear-gradient(
        180deg,
        #130e23 0%,
        #eb21a0 50.3%,
        #130e23 100%
    );
    /* transform: rotate(-90deg); */
}

/* step answer container which includes btn also........................................*/

.stepsFeedbackBtnContainer {

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin-block-start: 1em;
}

.mainFeedBackBtns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    background-color: #08091c;
    border-radius: 0.5rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
	
    font-size: 12px;
    letter-spacing: 1px;
    color: #f9f9f9;
}

.feedbackBtn {
    font-size: 18px;
    padding-block: 0.5em;
    padding-inline: 1em;
}

.feedbackBtn1:hover {
    background: #f8219f;
    color: #f9f9f9;
    border-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.feedbackBtn2:hover {
    background: #f8219f;
    color: #f9f9f9;
}

.feedbackBtn3:hover {
    background: #f8219f;
    color: #f9f9f9;
    border-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.centerFeedbackBtn {
    height: 2rem;
    width: 1px;
    border-radius: 0px;
    background: linear-gradient(
        180deg,
        #08091c 0%,
        #eb21a0 50.3%,
        #08091c 100%
    );
}

.secFeedBackBtn {
    flex-shrink: 0;
    margin-inline: 0.5rem;
    color: #000;
    width: 20px;
    height: 20px;
}

@media (min-width: 600px) {
    .secFeedBackBtn {
        display: none;
    }
}

.curr-step-border {
    border: 0.2rem solid #f8219f;
    scroll-behavior: smooth;
}

.curr-step-border + .display-step{
	min-height: 6rem;
}

.curr-step-background {
    position: absolute;
    opacity: 0.1;
    height: fit-content;
    width: 77%;
}

.remaining-step-border {
    border: 0.1rem solid #727272;
}

.ended-step-border {
    border: 0.2rem solid #2f99d7;
}

.showStepBtn {
    flex-shrink: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    color: #f8219f;
    background-color: transparent;
    border: 0.2rem solid #f8219f;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
}

.stepAnswerContainer {

    width: 100%;
    /* display: flex;
    justify-content: space-between; */
	gap: .5em;
    /* overflow-x: auto; */
    /* height: 50%; */


}

.explainAndExpandBtn {
    flex-shrink: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;

	/* min-width: 100px; */
}

/* .openExplainationBtn {
    font-weight: 600;
    font-size: 1.5rem;
    margin-right: 2rem;
} */

.expandedHeadingOfStep {
    font-family: "Poppins";
    padding: 0.5rem 0;
    font-size: 1.5rem;
}

/* below css is for the timer */
.boundaryvalueAnalysisForTimerInSteps {
    width: 100%;
    height: 100%;
    background-color: #252525;
}


.top {
    position: absolute;
    border-radius: 100vmax;
    left: 0;
    top: 0;
    /* border-bottom: 4px solid #21F869; */
    height: 3px;
    transform: scaleX(-1);
    background-color: #f8219f;
}

.bottom {
    position: absolute;
    border-radius: 100vmax;
    right: 0;
    bottom: 0;
    height: 3px;
    transform: scaleX(-1);
    background-color: #f8219f;
}
.right {
    position: absolute;
    border-radius: 100vmax;
    top: 0;
    right: 0;
    width: 3px;
    background: #f8219f;
    display: flex;
    flex-direction: column-reverse;
}

.left {
    position: absolute;
    border-radius: 100vmax;
    left: 0;
    bottom: 0;
    width: 3px;
    background: #f8219f;
}

.top {
    animation: animateTop var(--progi) ease-in;
    animation-play-state: paused;
}

.top.animStop {
    animation-play-state: paused;
}

.bottom {
    animation: animateBottom var(--progi) ease-in;
}

.right {
    animation: animateRight var(--progi) ease-in;
    animation-play-state: paused;
}

.left {
    animation: animateLeft var(--progi) ease-in;
}

@keyframes animateTop {
    0% {
        width: 10%;
    }
    40%,
    100% {
        width: 100%;
    }
}
@keyframes animateBottom {
    0%,
    49% {
        opacity: 1;
        width: 0;
    }
    90%,
    100% {
        width: 100%;
    }
}

@keyframes animateRight {
    0%,
    39% {
        opacity: 1;
        height: 0;
    }
    50% {
        opacity: 1;
        height: 100%;
    }
    100% {
        height: 100%;
        /* opacity: 0; */
    }
}

@keyframes animateLeft {
    0%,
    89% {
        /* opacity: 0; */
        bottom: 0;
        height: 0;
    }
    100% {
        /* opacity: 1; */
        height: 100%;
    }
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}


/* this is the feedback model css */

.feedbackBoxMainTitle {
    position: absolute;
    width: 169px;
    height: 34px;
    left: 876px;
    top: 375px;

    /* H3_Bold */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
    /* identical to box height, or 106% */

    letter-spacing: 1px;

    color: #f9f9f9;
}

.feedbackBoxCloseBtn {
    position: absolute;
    left: 8.33%;
    right: 8.33%;
    top: 8.33%;
    bottom: 8.33%;

    background: #2f99d7;
}

.feedbackBoxLinerGradient {
    position: absolute;
    left: 31.25%;
    right: 68.65%;
    top: 40.28%;
    bottom: -6.94%;

    background: linear-gradient(
        180deg,
        #130e23 0%,
        #eb21a0 50.3%,
        #130e23 100%
    );
    transform: rotate(-90deg);
}

.feedbackBoxTextArea {
    position: absolute;
    width: 720px;
    height: 230px;
    left: 600px;
    top: 475px;

    background: #100c2a;
    border-radius: 10px;
}

.showStepBtnContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    display: flex;
    justify-content:center;
    align-items: center;
    right: revert;
}

@media screen and (max-width: 1000px) {
    /* .display-step {
        font-size: 1.4vw;
    } */
    /* .display-step-no {
        min-width: 10vw;
        font-size: 0.8em;
    } */
    .expandedHeadingOfStep {
        font-size: 1.75vw;
    }
}

@media screen and (max-width: 600px) {
    .display-step-container {
        /* display: flex; */
        /* flex-direction: column; */
        /* margin: 0; */
    }

    .display-step-no {
        position: relative;
        left: 0%;

        background: #130e23;
        border-radius: 6px;
    }

    .display-step {
        /* position: relative; */
        /* width: 93%; */
        /* width: 100%; */
        display: flex;

        font-weight: 500;
		line-height: 1.2;
        /* identical to box height, or 100% */

        letter-spacing: 1px;

        color: #f9f9f9;
    }

    .displayStepSubContainer {
        overflow-y: auto;
        height: 100%;
        width: 100%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        max-height: 80vh;
    }

	/* .mainFeedBackBtns {
        margin: 0rem 0.5rem;
    } */

    .stepsFeedbackBtnContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-block: 1em; */
    }

    .feedbackBtn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }

    .expandedHeadingOfStep {
        font-size: 1.2rem;
        /* font-family: "Poppins"; */
    }

    .explainAndExpandBtn {

		display: flex;
        align-items: center;
        justify-content: space-between;
		
		min-width: 32px;
    	max-width: 64px;
    }

    .explainAndExpandBtn img { 
        flex-shrink: 0;
        width: 20px;
    }
	
	.explainAndExpandBtn.rotate {
        flex-direction: column;
	}

    .expandedHeadingOfStep {
        font-size: 14px;
    }

}

