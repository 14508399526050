.progressBarHead {
    display: flex;
    gap: 1em;
    border-radius: var(--border-10);
    margin-block-end: 2em;
}


.barHeadProgress {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: .5em 1em;
    border-radius: var(--border-6);

    min-width: 55px;
}

.barHeadProgress__zero {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 55px;
    height: 55px;
    background-color: rgb(79, 79, 94);
    border-radius: var(--border-10);

    font-weight: 700;
    font-size: var(--font-size-5lg);
}


.questionValueNames {

    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;

    font-size: var(--font-size-5lg);
    line-height: 34px;
    letter-spacing: 1px;
}


.statusValueProgressChart {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    margin-inline-start: .5em;
    font-size: var(--font-size-3lg);
    letter-spacing: 1px;

    color: #222222;
}


.incProgressUpAndDown__img {
    width: 20px;
    margin-left: 1em;
}

.endingStarIcon {
    width: 20px;
    align-items: flex-end;
    flex-shrink: 0;
}

