/* Not Responsive Yet */

.blog-view {
	margin-top: 6em;
	padding-inline: 1em;
	color: var(--text-primary-clr);
	font-family: 'Poppins';
}

@media (width > 900px) {
	.blog-view {
		width: 100%;
		max-width: var(--app-width);
		margin-inline: auto;
	}
}

.blog-view__heading{
	padding-block: 1rem;
	
	font-size: var(--font-size-8lg);
	font-weight: 700;
}

.blog-view__body-container {
	display: grid;
	grid-template-columns: 2fr 1fr;
	gap: 1em;
}

@media (width < 1024px) {
	.blog-view__body-container {
		display: grid;
		grid-template-columns: 1fr;
	}
}

.blog-view__main-container {
	padding-block: 0;
}

