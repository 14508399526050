.division-card {
    width: 330px;
    padding: 1em;
    border: 2px solid var(--stroke-primary-clr);
    border-radius: var(--border-10);
    text-align: center;
}

.division-card__question-no {
    font-weight: 700;
    font-size: 64px;
    line-height: 66px;
    letter-spacing: 1px;
    color: var(--accent-primary-clr);
}

.division-card__difficulty {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
    text-transform: capitalize;
}

.division-card__stars {
    display: flex;
    justify-content: center;
    margin-block: .5em;
}

.division-card__star-icon {
    width: 35px;
    aspect-ratio: 1/1;
}