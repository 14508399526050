/* body {
    background-color: rgb(8, 9, 28);
} */

.landingpage {
	/* font-family: 'Poppins'; */
    background-color: var(--bg-primary-clr);
    font-size: var(--primary-font-family);
    color: var(--text-primary-clr);
}

.landingpage__section-seperator {
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    padding-inline: 1em;
    line-height: 1.2;
}

/* hero section */

.hero-section-bg {
    background: linear-gradient(180deg,#f3219fd8 -98.75%,rgba(8,9,28,0) 54.52%);
}

.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3em;
    padding-block: 7em;
    padding-inline: 1em;
    margin-block-start: 4em;

    min-height: 80vh;
}

.hero-section__container {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

/* .hero-section__video {
    width: 100%;
    max-width: 773.61px;

    height: 100%;
    max-height: 503.98px;
    margin-right: auto;
    border-radius: var(--border-8);
} */

.hero-section__btn {
    margin-top: 1em;
    padding: 1em 2em;
    background: linear-gradient(270deg, #f8219f 0%, #7b28a9 100%);
    border-radius: 10px;
    font-family: "Poppins";
    font-weight: 600;
    font-size: var(--font-size-md);
    line-height: 28px;
    color: var(--text-primary-clr);
}

.hero-section__title {
    font-weight: 700;
    font-size: var(--font-size-36);
    line-height: 38px;
}

.hero-section__sub-title {
    font-size: 26px;
    color: var(--text-secondary-clr);
    line-height: 1.2;
}

.hero-section__sub-title__accented-text {
    color: var(--accent-primary-clr);
    font-size: 28px;
    font-weight: 700;
}


@media (min-width: 600px) {  
    .hero-section {
        flex-direction: row;
        justify-content: center;
        padding-block-start: 11em;
        width: 100%;
        max-width: var(--app-width);
        margin-inline: auto;
        min-height: 90vh;
    }

    /* .hero-section__container {
        align-items: flex-start; 
        justify-content: flex-start;
        text-align: start;
    } */

    .hero-section__title {
        font-size: 50px;
        line-height: 1.2;
    }

    /* .hero-section__sub-title {
        font-size: 28px;
    } */

    .hero-section__video {
        width: 100%;
        min-width: 400px;
    }

}

/* section one / two / three */

.section-one,
.section-two,
.section-three {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6em;
    padding-block: 4em;
    padding-inline: 1em;

}

.section-one__img-bg,
.section-two__img-bg,
.section-three__img-bg {
	display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}

.section-one__img,
.section-two__img,
.section-three__img {
    max-width: 340px;
    margin-inline: auto;
}

.section-one__container,
.section-two__container,
.section-three__container {
    text-align: center;
}

.section-one__title,
.section-two__title,
.section-three__title {
    font-weight: 700;
    font-size: var(--font-size-5lg);
    color: var(--stroke-primary-clr);
    line-height: 38px;
    margin-block-end: .5em;
}

.section-one__sub-title,
.section-two__sub-title,
.section-three__sub-title {
    font-size: var(--font-size-3lg);
    color: var(--text-secondary-clr);
}


@media (min-width: 600px) {  

    .section-two {
        flex-direction: row-reverse;
    }

    .section-one,
    .section-three {
        flex-direction: row;
    }

    .section-one,
    .section-two,
    .section-three {
        justify-content: space-between;
        padding-block: 8em;
        gap: 4em;

        width: 100%;
        max-width: var(--app-width);
        margin-inline: auto;
    }

    .section-one__img,
    .section-two__img,
    .section-three__img {
        width: 100%;
        min-width: 300px;
        max-width: 500px;
    }

    .section-one__container,
    .section-two__container,
    .section-three__container {
        text-align: left;
        width: 100%;
        max-width: 650px;
    }

    .section-one__title,
    .section-two__title,
    .section-three__title {
        margin-block-end: .5em;
    }
}

/* section four */

.section-four-container {
    background-color: var(--bg-secondary-clr);
}

.section-four {
    display: flex;
    flex-direction: column;
    gap: 4em;
    padding-block: 4em;
    padding-inline: 1em;

}

@media (width > 800px) {
    .section-four {
        flex-direction: row;
        justify-content: space-between;
        padding-block: 8em;
        width: 100%;
        max-width: var(--app-width);
        margin-inline: auto;
    }
}

.section-four__title {
    width: max-content;
    margin-inline: auto;
    font-weight: 700;
    text-align: center;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 1px;
    color: var(--icon-primary-clr);
}

.section-four__title.anim {
    animation: countAnimation 2s ease-in-out forwards;
}

.section-four__sub-title {
    text-align: center;
    font-weight: 400;
    font-size: var(--font-size-3lg);
    line-height: 1.2;
    letter-spacing: 1px;
    color: var( --text-secondary-clr);
    max-width: 300px;
    margin-inline: auto;
}

@media (min-width: 600px) {  
    .section-four__title { 
        font-size: 50px;
    }

    .section-four__sub-title { 
        font-size: 30px;
        line-height: 1.2;
    }
}

@keyframes countAnimation {
    from {
      content: "0%";
    }
    to {
      content: "76%";
    }
  }
 
 /* section five */
 
 .sectionfive {
     display: flex;
     justify-content: space-evenly;
     flex-direction: column;
 
     width: 100%;
     margin-inline: auto;
     padding-block-start: 10em;
     padding-block-end: 6em;
 
     background: var(--bg-primary-clr);
     /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25),
         inset 0px 8px 16px rgba(0, 0, 0, 0.25); */
     text-align: center;
 }
 
 .sectionfive__outer-container {
    background-color: var(--bg-secondary-clr);
    padding-inline: 1em;

 }
 
 .sectionfive__container {
     display: flex;
     flex-direction: column;
     /* justify-content: center; */
     align-items: center;

     gap: 1em;

     @media (min-width: 900px)  {
        width: 100%;
        max-width: var(--app-width);
        margin-inline: auto;
     }
 }

 /* delete this when css nesting works on firefox and safari. we are fools, we don't use postCSS */
   
 @media  (min-width: 900px) {
        .sectionfive__container { 
            width: 100%;
            max-width: var(--app-width);
            margin-inline: auto; 
        }
    }


 .sectionfive__inner-container {
     display: flex;
     flex-direction: column;
     justify-content: center;
     gap: 1em;

 }

 .sectionfive__title {
     font-size: var( --font-size-3lg);
     font-weight: 600;
     text-align: center;
     line-height: var(--line-height-small);
 }
 
 .sectionfive__inner-container p {
     font-size: var(--font-size-md);
 }
 
 .sectionfive__img1-container {
     margin-block-start: -8em;
     margin-block-end: -1.5em;
     flex-shrink: 0;
     width: 100%;
     min-height: 200px;
 }
 
 .sectionfive__img1 {
     width: 350px;
     margin-inline: auto;
 }
 
 .sectionfive__btn {
    @media (width < 900px) {
        margin-inline: auto;
    }
 }

 @media  (width < 900px) {
    .sectionfive__btn {
        margin-inline: auto;
     }
}
 
 .sectionfive__img2 {
     width: 200px;
 }
 
 @media (min-width: 900px) {

    .sectionfive {
        margin-block-start: 2em;
    }
 
    .sectionfive__container {
         flex-direction: row;
         gap: 3em;
     }
 
     .sectionfive__inner-container {
         justify-content: center;
         align-items: flex-start;
         text-align: start;
         justify-content: center;
         gap: 1em; 
     }

     .sectionfive__title {
        font-size: 30px;
        line-height: 1.2;
        font-weight: 700;
     }
 
     .sectionfive__img1-container {
         width: 35%;
         min-height: revert;
     }
 
     .sectionfive__inner-container h2 {
         text-align: start;
     }
     
     .sectionfive__inner-container p {
         font-size: var( --font-size-3lg);
     }
 
     .sectionfive__img1 {
         top: -150px;
         left: 0%;
         translate: 0 0;
         width: 100%;
         bottom: 0;
     }
 
     .sectionfive__startPracticising-btn {
         margin-inline: revert;
     }
 }
 
 @media (min-width: 1000px) {
 
    .sectionfive__title {
        font-size: 35px;
        line-height: 1.2;
        font-weight: 700;
     }
     
     .sectionfive__startPracticising-btn {
         padding: 14px 32px;
         background: linear-gradient(270deg, #f8219f 0%, #7b28a9 100%);
     }    
 }
 

/* section six */

.founder-info {
    padding: 1em;
    margin: .5em;
    margin-block: 4em;
}

.founder-info__img-container {
	position: relative;
    min-width: 131px;
    min-height: 293px;
}

.founder-info__img-backdrop,
.founder-info__img {
	position: absolute;
    width: 131px;
    height: 293px;
}

.founder-info__img-backdrop {
	background: linear-gradient(	
    	68deg,
        #4a435e 5%,
        #4a435d 5%,
        #4a435e 5%,
        rgba(74, 67, 94, 0) 50%);
	}
	
	.founder-info__img {
		top: -20px;
    left: 20px;
}

.founder-info__desc {
	color: var(--text-primary-clr);
}

.founder-info__info1,
.founder-info__info2 {
	position: absolute;
    bottom: 25px;
    left: 120px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: var(--fg-secondary-clr);
	
    width: 245px;
    padding: .5em;
    border-radius: var(--border-8);
}

.founder-info__title {
	font-size: var(--font-size-sm);
}

.founder-info__name {
	font-size: var(--font-size-2lg);
	font-weight: 600;
}

.founder-info__social-icons button {
    margin-inline: .25em;
}

.founder-info__social-icons img {
    width: 20px;
    aspect-ratio: 1/1;
}

@media (width > 800px) {
    .founder-info__social-icons button {
        margin-inline: .5em;
    }
    
    .founder-info__social-icons img {
        width: 25px;
        aspect-ratio: 1/1;
    }
}


.founder-info__desc {
	color: var(--text-secondary-clr);
    margin-block: 0.5em;
	max-width: 98%;
}

.founder-info__info2 {
	display: none;
}

.founder-info__socials{
	display: flex;
	gap: 1rem;
}

@media (min-width: 800px) { 
	.founder-info {
		display: flex;
        align-items: center;
        justify-content: center;
        gap: 2em;
        padding: 1em;
        margin-block: 4em;
        width: 100%;
        max-width: 650px;
        margin-inline: auto;
    }
	
	.founder-info__img-container {
		min-width: 250px;
    	min-height: 565px;
	}
	
	.founder-info__img-backdrop,
	.founder-info__img {
		position: absolute;
		width: 250px;
    	height: 570px;
	}

	.founder-info__img {
		top: -40px;
		left: 42px;
	}
	
    .founder-info__desc {
		margin: 1em 2em;
		max-width: 525px;
		letter-spacing: 0.5px;
		font-size: var( --font-size-2lg);
    }
	
    .founder-info__info1 {
		display: none;
    }
	
    .founder-info__info2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 15rem;

		border-radius: 0;
		min-width: max-content;
        padding: 2em 3em;
        width: auto;

        position: relative;
        top: 0;
        left: -4.5rem;
        z-index: 10;
    }

	
	.founder-info__title {
		font-size: var(--font-size-4lg);
	}

	.founder-info__name {
		font-size: var(--font-size-8lg);
		line-height: var(--font-size-8lg);
	}
}



