.explanationModal {
    font-family: "Poppins";
    position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -40%;

    display: flex;
    flex-direction: column;
    gap: 0.5em;

    width: 98%;
    max-width: 680px;

    background-color: var(--fg-primary-clr);
    padding: 1.2em 2.5em;
    text-align: center;
    border: 3px solid var(--icon-primary-clr);
    border-radius: var(--border-8);
    z-index: 20;
    animation: enlarge .25s ease forwards;
}

@keyframes enlarge {
    from {
        scale: .3;
    }
    to {
        scale: 1
    }
}

.explanationModalHeading {
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.5px;
    
    margin: 0 auto;
    font-size: var(--font-size-4lg);
    color: var(--text-primary-clr);
}

.explanationModalDivider img{
    padding: 0.5em 0 1em;
}

.explanationModal__checkbox-container {
	color: var(--text-primary-clr);
}

.explanationModal__subOptions.inv{
	opacity: 0;
	visibility: hidden;
}

.explanationModal__subOptions.vis{
	display: flex;
	width: 100%;
	gap: 1rem;
	opacity: 1;
	
	margin-inline-start: 2rem;
	visibility: visible;
    transition: opacity 1000ms;
}

@media (max-width: 500px){
	.explanationModal__subOptions.vis{
		flex-direction: column;
		gap: 0;
	}
}

.checkbo-icon {
    width: 20px;
    height: auto;
    /* object-fit: cover; */
}

.explanationModal__checkbox{
    display: flex;
    align-items: center;
    gap: 0.5em;
    text-align: left ;
    margin-block-end: 1em;
}

.checkbox__title {
    font-size: var(--font-size-md);
    font-weight: 500;
}

.explanationModalInputField {
    width: 100%;
    height: 200px;
    resize: none;
    background-color: var(--fg-secondary-clr);
    border-radius: var(--border-8);
    padding: 1em;
    font-size: var(--font-size-md);
    color: var(--text-primary-clr);
    line-height: var(--line-height-normal);
}

.explanationModalSubBtn {
    margin: auto;

    /* width: 8rem; */
    /* max-width: 146px;
    padding: .7em 1.5em;

    color: var(--text-primary-clr);
    font-family: inherit;
    font-weight: 600;
    letter-spacing: 0.75;
	text-align: center;
	
    border-radius: var(--border-6);
    font-size: var(--font-size-sm);
    background-image: linear-gradient(270deg, #f8219f 0%, #7b28a9 100%); */
}

.explanationModalCloseBtn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}
