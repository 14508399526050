.chapter-detail {
    padding-block-start: 8em;
    color: var(--text-primary-clr);
}

/* @media (width > 600px) {
    .chapter-detail {
        width: 100%;
        max-width: var(--app-width);
        margin-inline: auto;
    }
} */

/* chapter-detail__header  */

.chapter-detail__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    padding-inline: 1em;
}

@media (width > 600px) { 
    .chapter-detail__header {
        width: 100%;
        max-width: var(--app-width);
        margin-inline: auto;
        padding-block: 6em;
    }
}

@media (width > 1200px) {
    .chapter-detail__header {
        flex-direction: row;
    }
}

/* .chapter-detail__header__left {
    flex-grow: 1;
} */

.chapter-detail__header__right {
    width: 100%;
    width: 450px;
    aspect-ratio: 1/1;
    flex-shrink: 0;
}

@media (width > 600px) {
    .chapter-detail__header__right {
        width: 350px;
    }
}

.chapter-detail__header-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.3;
    letter-spacing: 0.5px;
}

@media (width > 600px) {  
    .chapter-detail__header-title {
        font-weight: 700;
        font-size: 48px;
        line-height: 72px;
        letter-spacing: 0.5px;
    }
}

.chapter-detail__header-question-n-time {
    display: flex;
    gap: 1em;
}

.chapter-detail__question,
.chapter-detail__time {
    color: var(--text-secondary-clr);
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
}

.chapter-detail__header-btn-container {
    display: flex;
    gap: 1em;
    margin-block: 1.5em;
}

.chapter-detail__header-chapter-summary {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4 ;
    letter-spacing: 0.25px;
    color: var(--text-primary-clr);
}

.chapter-detail__header-chapter-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-inline: auto;
    border-radius: var(--border-10);
}

/* chapter-detail__division */

.chapter-detail__division-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
}

.chapter-detail__division-sub-title {
	font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: var(--text-secondary-clr);
}

.hide-big .swiper-slide {
	height: 150px;
}


.chapter-detail__division-container {
    padding-block: 3em;
    padding-inline: 1em;
    background-color: var(--bg-secondary-clr);
}

.chapter-detail__divisions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: .5em;
    margin-block-start: 1em;
  
}

@media (width > 1024px) {
    .chapter-detail__divisions {
        flex-wrap: revert;
    }
}

.chapter-detail__coverages-container {
    padding-block: 3em;
    margin: 1em;
}

.chapter-detail__coverages {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    margin-block-start: 1em;
    width: 100%;
}

/* @media (width > 1024px) {
    .chapter-detail__coverages {
        display: flex;
    }
} */

.chapter-detail__content-title {
    font-weight: 700;
    font-size: 44px;
    line-height: 50px;
    letter-spacing: 1px;
    text-align: center;
    color: var(--text-primary-clr);
}

.chapter-detail__contents-container {

    padding-block: 3em;
    margin: 1em;
    background-color: var(--bg-secondary-clr);
}

.chapter-detail__contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
}